import _ from "lodash";
import { Skeleton } from "@mantine/core";
import { VehicleImage } from "./image";

const _randomPercentage = () => {
    const randomPercentage = Math.random() * (90 - 30) + 30;
    const roundedPercentage = Math.round(randomPercentage * 100) / 100;

    return roundedPercentage
} 

// 
// common
export const SkeletonTag = (props) => {
    const { } = props
    return (
        <div className="skeletonTagItem">
            <div className="absoluteSkeletonBox">
                <Skeleton 
                    height={'100%'}
                    radius={`2rem`}
                />
            </div>
        </div>
    )
}
export const SkeletonParagraph = (props) => {
    const { row = 2, isTitle = false, isDate = false} = props
    if(isTitle == true) {
        return (
            <>
                {
                    _.times(row, (key)=>(
                        <div key={key} className="skeletonParagraphRowTitle">
                            <Skeleton height={'100%'} width={key == 0 ? '100%' : `80%` } radius={'5rem'}/>
                        </div>
                    ))
                }
            </>
        )
    } else if (isDate == true){
        return (
            <div className="skeletonParagraphRowDate">
                <Skeleton height={'100%'} radius={'5rem'}/>
            </div>
        )
    } else {
        return (
            <>
                {
                    _.times(row, (key)=>(
                        <div key={key} className="skeletonParagraphRow">
                            <Skeleton height={'100%'} width={key == 0 ? '100%' : `80%` } radius={'5rem'}/>
                        </div>
                    ))
                }
            </>
        )
    }
}
export const SkeletonCategory = (props) => {
    const { item = 3} = props
    return (
        <div className="newsCategory_list containerPadding">
            {
                _.times(item, (key)=>(
                    <div key={key} className="newsCategory_itemSkeleton">
                        <div className="absoluteSkeletonBox">
                            <Skeleton 
                                height={'100%'}
                                radius={`2rem`}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export const SkeletonBrandItem = (props) => {
    return (
        <div className='homePage_popularBox_brand_item cursorPointer'>
            <div className='homePage_popularBox_brand_item_imageOuter'>
                <div className='absoluteSkeletonBox'>
                    <Skeleton height={'100%'} radius={'50%'}/>
                </div>
            </div>
            <div className='fontSize_12 homePage_popularBox_brand_item_text'>
                <SkeletonParagraph row={1} isTitle={true} />
            </div>
        </div>
    )
}

export const SkeletonTyreBrandItem = (props) => {
    return (
        <div className='homePage_popularBox_brand_item cursorPointer' style={{width: 'unset'}}>
            <div className='homePage_popularBox_brand_item_imageOuter' style={{width:'9.5rem'}}>
                <div className='absoluteSkeletonBox'>
                    <Skeleton height={'100%'} />
                </div>
            </div>
        </div>
    )
}

// News
export const SkeletonVideoItem = () => {
    return (
        <div className='news_video containerPadding'>
            <div className='news_video_box'>
                <div className='news_video_imageSkeleton' >
                    <div className="absoluteSkeletonBox">
                        <Skeleton height={'100%'} />
                    </div>
                </div>
                <div className='news_video_content'>
                    <div className='fontSize_14 fontWeight_semiBold news_video_text'>
                        <div>
                            <SkeletonParagraph row={2} isTitle={true} />
                        </div>
                    </div>
                    <div className='news_video_bottom'>
                        <div></div>
                        <div className='fontSize_12 news_video_date'>
                            <SkeletonParagraph row={1} isDate={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const SkeletonLatestNewsItem = () =>{
    return (
        <div className="latestNews containerPadding">
            <div>
                <SkeletonParagraph row={2} isTitle={true} />
            </div>
            <div>
                <SkeletonParagraph row={1} isDate={true} />
            </div>
            <div className="latestNews_imageSkeleton">
                <div className="absoluteSkeletonBox">
                    <Skeleton height={'100%'} />
                </div>
            </div>
        </div>
    )
}

export const SkeletonNewsItem = (props) =>{
    const {lineVisible = false} = props
    return (
        <>
            { 
                lineVisible && 
                <div className="dividingLine"></div> 
            }
            <div className='containerPadding'>
                <div className='news_itemBox'>
                    <div className='fontSize_14 fontWeight_semiBold'>
                        <SkeletonParagraph row={2} isTitle={true} />
                    </div>
                    <div className='news_itemBox_imageList'>
                        <div className="news_itemBox_imageSkeleton">
                            <div className="absoluteSkeletonBox">
                                <Skeleton height={'100%'} radius={'1rem'}/>
                            </div>
                        </div>
                        <div className="news_itemBox_imageSkeleton">
                            <div className="absoluteSkeletonBox">
                                <Skeleton height={'100%'} radius={'1rem'}/>
                            </div>
                        </div>
                        <div className="news_itemBox_imageSkeleton">
                            <div className="absoluteSkeletonBox">
                                <Skeleton height={'100%'} radius={'1rem'}/>
                            </div>
                        </div>
                    </div>
                    <div className='news_itemBox_bottom'>
                        <div className='news_itemBox_tag'>
                            {/* <TagButton /> */}
                            
                        </div>
                        <div className='fontSize_12 news_latestNews_date'>
                            <SkeletonParagraph row={1} isDate={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// ranking
export const SkeletonSpeedTestRow = (props) => {
    const { } = props
    return (
        <div className='rankingPage_item'>
            <div className='rankingPage_item_ranking'>
                <Skeleton width={'2rem'} height={'2rem'} circle />
            </div>
            <div className='rankingPage_item_image'>
                <VehicleImage loading={true} />
            </div>
            <div className='rankingPage_item_content'>
                <div className='fontSize_14 fontWeight_semiBold'>
                    <SkeletonParagraph row={1} isTitle={true} />
                </div>
                <div className='fontSize_12 fontWeight_semiBold'>
                    <SkeletonParagraph row={2} />
                </div>
            </div>
            <div className='rankingPage_item_sec'>
                <div className='fontSize_14 fontWeight_bold fontColor_red fontStyle_italic'>
                    <SkeletonParagraph row={1} isTitle={true} />
                </div>
            </div>
        </div>
    )
}
