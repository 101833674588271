import { memo, useEffect } from "react";
import _ from "lodash";
import { Skeleton } from "@mantine/core";
import { _increasePlacementClick, _increasePlacementImpression } from "../../libraries/common";
import { customRouterLink } from "../../libraries/appCommon";
import { useRouter } from "next/router";

export const PlacementBanner = memo(function PlacementBanner({loading = false, disabledClick = false, area, placement_id, type, image_path, url, script}) {
    if(loading == true){
        return (
            <div className="placementBox">
                <Skeleton visible={true} style={{height: '30rem'}} />
            </div>
        )
    }

    if(_.isEmpty(placement_id)){
        return <></>
    }
    const router = useRouter()
    useEffect(()=>{
        const fetchData = async() => {
            // can't click so impression no counting
            // using on gallery popout bottom image list
            if(disabledClick != true){
                await _increasePlacementImpression({placement_id})
            }
        }
        fetchData()

        if(type == 1){
            const scriptElement = document.querySelector(
                `script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3254799400709253"]`
            )
            const handleScriptLoad = async() => {
                try{
                    if(window.adsbygoogle) {
                        adsbygoogle.push({});
                    } else {
                        scriptElement.addEventListener("load", handleScriptLoad)
                    }
                }catch(error){
                    console.log(error)
                }
            }

            handleScriptLoad()

            return () => {
                if(scriptElement) {
                    scriptElement.removeEventListener("load", handleScriptLoad)
                }
            }
        }
    },[])
    

    const _handleDivClick = async() => {
        // can't click
        // using on gallery popout bottom image list
        if(disabledClick != true){
            await _increasePlacementClick({placement_id})
            customRouterLink({router: router, href: url, action: 'navigationTab', target: "_blank"})
            // window.location.href = url; // replace link
        }
    }

    if(type == 1 && !_.isEmpty(script)){
        // header adsbygoogle.js see article only load once
        return (
            <>
                <div style={{overflow: 'hidden'}} >
                    <div 
                        className="placementBoxGoogle" 
                        dangerouslySetInnerHTML={{
                            __html: `${script}`
                        }}
                    />
                </div>
            </>
        )
    }

    if(type == 2){
        return (
            <div className="placementBox" onClick={_handleDivClick}>
                <img 
                    className='w-100'
                    src={`${process.env.NEXT_PUBLIC_FS_URL}${image_path}`}
                />
            </div>
        )
    }

    if(type == 3){
        return (
            <div className="placementBox" >
                <div className="placementBoxIframe" dangerouslySetInnerHTML={{
                    __html: `
                        ${script}
                    `,
                }} 
                >
                </div>
                {
                    type == 3 &&
                    <div className="placementFrontLayer" onClick={_handleDivClick} />
                }
            </div>
        )
    }

    return (<></>)
})