import Link from "next/link"
import { pageLink } from "../../config/site"
import { useRouter } from "next/router"
import { CustomLink } from "../app/customLink"
import { customRouterLink } from "../../libraries/appCommon"


export const Footer = (props) => {
    const {t, isLoadedInFlutterApp = false} = props
    const router = useRouter()

    return (
        <div className="footer">
            <div className="footer_container containerPadding">
                <div className="footer_top">
                    <CustomLink href={'/'}>
                        <div className='footer_logo cursorPointer'>
                            <img className='w-100' src="/assets/images/logo.png" />
                        </div>
                    </CustomLink>
                    <p className="fontSize_12 fontColor_grey">
                        {t('footer_description')}
                    </p>
                    {/* <p className="fontSize_14 fontWeight_semiBold fontColor_white">
                        {t('contact_us')}
                    </p>
                    <a href="mailto:contactUs@keyauto.my" target="_blank" rel="noopener noreferrer">
                        <p className="fontSize_12 fontWeight_medium fontColor_lightBlue">
                            ContactUs@KeyAuto.my
                        </p>
                    </a> */}
                </div>
                
                <div className="footer_follow_us">
                    <p className="fontSize_14 fontWeight_semiBold fontColor_white">
                        {t('follow_us')}
                    </p>
                    <div className="footer_follow_us_list">
                        <a onClick={()=>{customRouterLink({href: router.locale == "en" ? "https://www.facebook.com/profile.php?id=61554207628409" : "https://www.facebook.com/keyauto.my", action: 'navigationTab', target: "_blank"})}} className="footer_follow_us_list_media_icon" rel="noopener noreferrer">
                            <img className='w-100' src="/assets/images/facebook_icon.svg" />
                        </a>
                        <a onClick={()=>{customRouterLink({href: "https://www.instagram.com/keyauto.my/", action: 'navigationTab', target: "_blank"})}} className="footer_follow_us_list_media_icon" rel="noopener noreferrer">
                            <img className='w-100' src="/assets/images/instagram_icon.png" />
                        </a>
                        <a onClick={()=>{customRouterLink({href: "https://youtube.com/@KeyautoMyPlus", action: 'navigationTab', target: "_blank"})}} className="footer_follow_us_list_media_icon" rel="noopener noreferrer">
                            <img className='w-100' src="/assets/images/youtube_icon.svg" />
                        </a>
                        <a onClick={()=>{customRouterLink({href: "https://www.xiaohongshu.com/user/profile/6262ca2f00000000100093d7", action: 'navigationTab', target: "_blank"})}} className="footer_follow_us_list_media_icon" rel="noopener noreferrer">
                            <img className='w-100' src="/assets/images/xhs_icon.png" />
                        </a>
                        <a onClick={()=>{customRouterLink({href: "https://www.tiktok.com/@keyauto.my", action: 'navigationTab', target: "_blank"})}} className="footer_follow_us_list_media_icon footer_follow_us_list_media_icon_tiktok" rel="noopener noreferrer">
                            <img className='w-100' src="/assets/images/tiktok_icon.png" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer_bottom containerPadding">
                <div className="footer_bottom_list">
                    <CustomLink href={pageLink.general.aboutUs}>
                        <p className="fontSize_12 fontColor_grey3 cursorPointer">
                            {t('about_us')}
                        </p>
                    </CustomLink>
                    <p className="fontSize_14 fontColor_grey">
                        |
                    </p>
                    <CustomLink href={pageLink.general.privacyPolicy}>
                        <p className="fontSize_12 fontColor_grey3 cursorPointer">
                            {t('privacy_policy')}
                        </p>
                    </CustomLink>
                </div>
                <p className="fontSize_12 fontColor_grey fontWeight_medium">
                    © 2015-2023, KeyAuto.my All Right Reserved
                </p>
            </div>
        </div>
    )
}