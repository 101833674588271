import ScrollContainer from "react-indiana-drag-scroll"
import { toast } from "@/libraries/swal";
import { Skeleton } from "@mantine/core";
import _ from "lodash";

export const CommonCategory = (props) => {
    const { data = [], activeCategory, setActiveCategory, t, isAllAllow = true, isFull = false, loading = false, isSubTab = false} = props

    if(loading == true ){
        return (
            <ScrollContainer
                className={`newsCategory_list containerPadding ${isSubTab == true ? 'commonCategory_list' : 'newsCategory_list'}`}
                vertical={false}
                horizontal={true}
            >
                {
                    _.times(3, (key)=>(
                        <div key={key} className="newsCategory_item positionRelative">
                            <div className="absoluteSkeletonBox">
                                <Skeleton
                                    height={'100%'}
                                    radius={`2rem`}
                                />
                            </div>
                        </div>
                    ))
                }
            </ScrollContainer>
        )
    }
    return (
        <>
            <ScrollContainer
                className={`containerPadding ${isSubTab == true ? 'commonCategory_list' : 'newsCategory_list'}`}
                vertical={false}
                horizontal={true}
            >
                {
                    isAllAllow &&
                    <div onClick={()=>setActiveCategory(0)}>
                        <div className={`fontSize_14 ${0 == activeCategory ? 'newsCategory_text newsCategory_textActive' : 'newsCategory_text'}`}>
                            {t('all')}
                        </div>
                        {
                            0 == activeCategory &&
                            <div className='newsCategory_active' />
                        }
                    </div>
                }
                {
                    _.reduce(data, (result, value, key) => {
                        result.push(
                            <div 
                                key={key} 
                                onClick={()=>{value?.isComingSoon == true ? toast.comingSoon() : setActiveCategory(value.id)}}
                                className={`${isFull == true && "newsCategory_itemFull"}`}
                            >
                                <div className={`fontSize_14 ${value.id == activeCategory ? 'newsCategory_text newsCategory_textActive' : 'newsCategory_text'}`}>
                                    {value.text}
                                </div>
                                {
                                    value.id == activeCategory &&
                                    <div className='newsCategory_active' />
                                }
                            </div>
                        )
                        return result;
                    }, [])
                    
                }
            </ScrollContainer>
        </>
    )
}