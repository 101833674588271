import { isEmpty } from "lodash";
import Link from "next/link";
import { Footer } from "../common/footer";
import { connect } from "react-redux";
import { useRouter } from "next/router";
import { useViewportSize } from "@mantine/hooks";

const CustomBody = (props) => {
    const {
        t,
        children = <></>, 

        headerComponent = null, 
        isSubTab = false,


        navigationComponent = null, 
        showNavigation = false,

        modalComponent = null, 
        isLoadedInFlutterApp = false,
        showFooter = true,

        containerRef = null,
        containerStyle = {},
        containerClass = ""
    } = props;
    const router = useRouter();
    const queryParams = new URLSearchParams(router.asPath.split('?')[1]);
    const isWebview = queryParams.get('isWebview') == "true" ? true : false;

    const containerClasses = [
        "contentContainer",
        containerClass,
        !isEmpty(navigationComponent) && (isLoadedInFlutterApp || isWebview) && showNavigation && "bodyContainerHasBottomNavigation",
    ].filter(Boolean).join(' ');

    const outerStyle = (isLoadedInFlutterApp || isWebview) ? { paddingTop: 'unset' } : {};

    // bodyContainerWithSubTab
    return (
        <div className={`bodyContainer ${isSubTab == true ? 'bodyContainerWithSubTab' : ''}`} style={outerStyle}>
            <div 
                ref={containerRef} 
                className={containerClasses}
                style={containerStyle}
            >
                {
                    headerComponent
                }
                <div style={{flex: 1}}>
                {
                    children
                }
                </div>
                {
                    showFooter == true &&
                    <Footer 
                        t={t} 
                        isLoadedInFlutterApp={isLoadedInFlutterApp}
                    />
                }
            </div>
            {
                (isLoadedInFlutterApp == true || isWebview == true) ? 
                    showNavigation == true &&
                    navigationComponent
                :
                    navigationComponent
                    
            }
            {
                modalComponent
            }
        </div>
    )
}

// mapStateToProps function to map Redux state to component props
const mapStateToProps = (state) => {// Log the Redux state
    return {
        isLoadedInFlutterApp: state?.path?.isLoadedInFlutterApp ?? false, // Assuming 'isLoadedInFlutterApp' is the Redux state you want to access
    };
};


// Connect the component to Redux
export const MyBody = connect(mapStateToProps)(CustomBody);