import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { useViewportSize } from "@mantine/hooks";
import { FaCheck, FaTimes } from "react-icons/fa";
import { VehicleImage } from "../common/image";
import { _getPlacement, _numToPrice } from "../../libraries/common";
import { IconStar } from "../common/icon";
import { hasCookie, getCookie, setCookie } from "cookies-next";
import { compareResultGridRow, mobileViewWidth, pageLink, placementArea } from "../../config/site";
import Link from "next/link";
import Redux from "@/action";
import { PlacementBanner } from '@/components/placement';
import { LoadingOverlay } from "@mantine/core";
import { CustomLink } from "../app/customLink";

const specList = {
    // "Variant": "2018 Proton X70 1.8 TGDI Standard 2WD",
    "Price": [
        "Peninsular",
        "Sabah",
        "Sarawak",
        "Labuan",
        "Langkawi"
    ],
    "Costs": [
        "Insurance", 
        "Road Tax", 
        "Monthly Payment"
    ],
    "Overview": [
        "Brand",
        "Body Type",
        "Segment",
        "Fuel",
        "Model",
        "Generation",
        "Launched Year",
        "Horsepower (ps)",
        "Torque (Nm)",
        "Engine",
        "Electric Engine (PS)",
        "Transmission",
        "Length * Width * Heigh(mm)",
        "0-100 km/h (s)",
        "Manufacturers Claim (L/100km)",
        // "As Tested (L/100km)",
        "On Sale",
        "Warranty Manufacturer",
        "Top Speed (km/h)"
    ],
    "Color": [
        "Exterior Color",
        "Interior Color"
    ],
    "Dimensions": [
        "Length (mm)",
        "Width (mm)",
        "Height (mm)",
        "Wheelbase (mm)",
        "Weight (kg)",
        // "Ground Clearance",
        // "Approach Angle",
        // "Departure Angle",
        "Doors",
        "Seats",
        "Fueltank(litres)",
        "Boot Volume(L)"
    ],
    "Powertrain": [
        "Capacity (L)",
        "Aspiration Form",
        "Cylinder Arrangement",
        "Number of Cylinders",
        "Compression Ratio",
        "Valvetrain",
        "Timing Type",
        "Horsepower (PS)",
        "Rpm at Max Hp",
        "Torque(Nm)",
        "Rpm at Max torque"
    ],
    "Electric Motor": [
        "Motor Type",
        "Motor Output (PS)",
        "Motor Torque(Nm)",
        "Front Motor Output (PS)",
        "Front Motor Torque (Nm)",
        "Rear Motor Output (PS)",
        "Rear Motor Torque (Nm)",
        "Combined System Output (PS)",
        "Combined System Torque (Nm)",
        "Number Of Motors",
        "Motor Arrangement",
        "Battery Type",
        "EV Range(km)",
        "Battery Capacity (kWh)",
        "Charging Rate (DC)",
        "Charger Type (DC)",
        "Charging Rate (AC)",
        "Charger Type (AC)",
        "Power Consumption Per 100 Kilometers (kWh)",
        "Fast Charging (0% - 80%)",
        // "Slow Charge Time (h)",
        "Battery Warranty",
        "Features"
    ],
    "Drivetrain": [
        "Transmission",
        "Forward Ratios",
        "Transmission Manufacture",
        // "Conventional Shift Mode",
        "Driven Wheels",
    ],
    "Chassis": [
        "Assembly",
        "Chassis Type",
        "Front Suspension",
        "Rear Suspension",
        "Adaptive Suspension",
        // "Front Tyres",
        // "Rear Tyres",
        // "Spare Tyre"
    ],
    "Tyre": [
        "Brand",
        "Tyres",
        "Front Tyres",
        "Front Wheels",
        "Rear Tyres",
        "Rear Wheels",
        "Spare Tyre",
        "Tyre Pressure Monitoring System",
    ],
    "Brakes": [
        "Front Brakes",
        "Rear Brakes",
        "Steering",
        "Parking Brake"
    ],
    "Safety": [
        "Euro NCAP Rating",
        "ASEAN NCAP Rating",
        "Airbags",
        "Driver/Front Passenger Seat Airbags",
        "Front/Rear Side Airbags",
        "Front/Rear Curtain Airbags",
        "Knee Airbags",
        "Rear Airbags",
        "Airbag Disable Function",
        "Seatbelt Reminder",
        // "Autonomous Emergency Braking",
        // "Lane-keeping Alert",
        // "Blind Spot Info System",
        // "Collision Warning",
        "Child Safety Lock",
        "ISOFIX",
        "Security Alarm",
        "Immobilizer",
        "ABS/EBD",
        "Electronic Stability Control (ESC)",
        "Traction Control System",
        "Auto Brake Hold"
    ],
    "Advanced Driver Assistance System": [
        "Autonomous Emergency Braking",
        "Collision Warning",
        "Adaptive Cruise Control (ACC)",
        "Intelligent Cruise Control (ICC)",
        "Lane Keep Assist",
        "Lane Departure Warning",
        "Traffic Sign Information (TSI)",
        "Blind spot monitoring",
        "Rear Cross Traffic Alert",
        "Door Opening Warning"
    ],
    "Assist System": [
        "Parking Sensor Front",
        "Parking Sensor Rear",
        "Parking Camera",
        "Cruise Control",
        "Auto Parking",
        "Auto Start/Stop",
        "Hill Start Assist"
    ],
    "Exterior": [
        "Door Lock",
        "Folding Wing Mirrors",
        "Auto Wipers",
        "Boot Operation",
        "Soft Closing Door"
    ],
    "Lighting": [
        "Headlamps",
        "Taillamps",
        "Daytime Running Lights",
        "Front Foglamps",
        "Rear Foglamps",
        "Interior Lighting",
        "Auto Headlamps"
    ],
    "Interior": [
        "Sunroof",
        "Sunshade",
        "Seat Features (Front-Driver)",
        "Seat Features (Passenger)",
        "Seat Features (Rear)",
        "Cupholders",
        "Front Air-con",
        "Rear Air-con",
        "Paddle Shift",
        "Multi-function Steering Wheel",
        "Steering Tilt",
        "Steering Telescopic",
        "Steering Adjustment Electric",
        "Engine Start",
        "Head-up Display"
    ],
    "Infotainment System": [
        "Instrument Cluster",
        "Screen",
        "Screen Size (inch)",
        "Navigation",
        "Rear Entertainment",
        "Bluetooth",
        "Front USB Ports",
        // "AUX",
        // "CD",
        "Rear USB Ports",
        "Wireless Charger",
        "Voice Command",
        "Apple CarPlay",
        "Android Auto",
        "Car App",
        "Speaker Brand",
        "Sound Plus Functions",
        "Speakers"
    ],
    "Package & Optional": []
}

const CheckOrCloseIcon = (props) =>{
    const { isYes } = props
    if(isYes == "Yes"){
        return (
            <FaCheck className="compareTableGridColum_checkIcon" />
        )
    }
    return (
        <FaTimes className="compareTableGridColum_closeIcon" />
    )
}

const GridColumn = (props) => {
    const { data = {}, getPackageMaxTotal = 0 } = props
    const classNameSame = "fontSize_14 fontWeight_medium compareTableGridBody"
    if(_.isEmpty(data)){
        return (
            <>
                {
                    //  149 row 149 * 8 = 1192
                    Array.from({ length: 171 }).map((_, i) => (
                        <div key={i} className={classNameSame}>

                        </div>
                    ))
                }
                {
                    // when is empty data show minimun row 
                    getPackageMaxTotal >= 1 && Array.from({ length: getPackageMaxTotal }).map((_, i) => (
                        <div key={`package-${i}`} className={classNameSame}>

                        </div>
                    ))
                }
            </>
        )
    } 

    return (
        <>
            <div className={classNameSame}>
                {_numToPrice(data.price)}
            </div>
            <div className={classNameSame}>
                {_numToPrice(data.price_sabah)}
            </div>
            <div className={classNameSame}>
                {_numToPrice(data.price_sarawak)}
            </div>
            <div className={classNameSame}>
                {_numToPrice(data.price_labuan)}
            </div>
            <div className={classNameSame}>
                {_numToPrice(data.price_langkawi)}
            </div>
            <div className={classNameSame}>
                {_numToPrice(data.insurance_cost)}
            </div>
            <div className={classNameSame}>
                {_numToPrice(data.road_tax)}
            </div>
            <div className={classNameSame}>
                {_numToPrice(data.monthly_payment)}
            </div>
            <div className={classNameSame}>
                { data.brand_name }
            </div>
            <div className={classNameSame}>
                { data.body_type }
            </div>
            <div className={classNameSame}>
                { data.segment }
            </div>
            <div className={classNameSame}>
                { data.fuel }
            </div>
            <div className={classNameSame}>
                { data.model_name }
            </div>
            <div className={classNameSame}>
                { data.generation }
            </div>
            <div className={classNameSame}>
                { data.launched_year }
            </div>
            <div className={classNameSame}>
                { data.horsepower } 
            </div>
            <div className={classNameSame}>
                { data.torque }
            </div>
            <div className={classNameSame}>
                { data.engine }
            </div>
            <div className={classNameSame}>
                { data.electric_engine }
            </div>
            <div className={classNameSame}>
                { data.transmission }
            </div>
            <div className={classNameSame}>
                { data.total_dimension }
            </div>
            <div className={classNameSame}>
                { data.speed_0to100 }
            </div>
            <div className={classNameSame}>
                { data.petrol_manufacturer_claim }
            </div>
            {/* <div className={classNameSame}>
                { data.petrol_tested }
            </div> */}
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.on_sale} />
            </div>
            <div className={classNameSame}>
                { data.warranty }
            </div>
            <div className={classNameSame}>
                { data.top_speed }
            </div>
            <div className={classNameSame}>
                <div className="compareTableColorColumn">
                {
                    data?.new_exterior_color != "-" ?
                            !_.isEmpty(JSON.parse(data?.new_exterior_color)) ?
                            _.reduce(JSON.parse(data?.new_exterior_color), (result, value, key)=>{
                                result.push(
                                    <div key={key} className="compareTableColorRow">
                                        <div className="compareTableColor" style={{backgroundColor: value?.color}} />
                                        <p className="fontSize_14 fontWeight_medium compareTableText">
                                            {value?.name}
                                        </p>
                                    </div>
                                )
                                return result
                            },[])
                            :
                            "-"
                        :
                        "-"
                }
                </div>
                {/* { data.exterior_color } */}
            </div>
            <div className={classNameSame}>
                <div className="compareTableColorColumn">
                {
                    data?.new_interior_color != "-" ?
                            !_.isEmpty(JSON.parse(data?.new_interior_color)) ?
                            _.reduce(JSON.parse(data?.new_interior_color), (result, value, key)=>{
                                result.push(
                                    <div key={key} className="compareTableColorRow">
                                        <div className="compareTableColor" style={{backgroundColor: value?.color}} />
                                        <p className="fontSize_14 fontWeight_medium compareTableText">
                                            {value?.name}
                                        </p>
                                    </div>
                                )
                                return result
                            },[])
                            :
                            "-"
                        :
                        "-"
                }
                </div>
                {/* { data.interior_color } */}
            </div>
            <div className={classNameSame}>
                { data.length }
            </div>
            <div className={classNameSame}>
                { data.width }
            </div>
            <div className={classNameSame}>
                { data.height }
            </div>
            <div className={classNameSame}>
                { data.wheelbase }
            </div>
            <div className={classNameSame}>
                { data.weight }
            </div>
            {/* <div className={classNameSame}>
                { data.ground_clearance }
            </div>
            <div className={classNameSame}>
                { data.approach_angle }
            </div>
            <div className={classNameSame}>
                { data.departure_angle }
            </div> */}
            <div className={classNameSame}>
                { data.doors }
            </div>
            <div className={classNameSame}>
                { data.seats }
            </div>
            <div className={classNameSame}>
                { data.fuel_tank }
            </div>
            <div className={classNameSame}>
                { data.boot_volume }
            </div>
            <div className={classNameSame}>
                { data.capacity }
            </div>
            <div className={classNameSame}>
                { data.aspiration_form }
            </div>
            <div className={classNameSame}>
                { data.cylinder_arrangement }
            </div>
            <div className={classNameSame}>
                { data.cylinder_number }
            </div>
            <div className={classNameSame}>
                { data.compression_ratio }
            </div>
            <div className={classNameSame}>
                { data.valvetrain }
            </div>
            <div className={classNameSame}>
                { data.timing_type }
            </div>
            <div className={classNameSame}>
                { data.horsepower }
            </div>
            <div className={classNameSame}>
                { data.horsepower_max_rpm }
            </div>
            <div className={classNameSame}>
                { data.torque }
            </div>
            <div className={classNameSame}>
                { data.torque_max_rpm }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_type }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_horsepower }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_torque }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_front_horsepower }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_front_torque }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_rear_horsepower }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_rear_torque }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_combine_horsepower }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_combine_torque }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_number }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_arrangement }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_battery_type }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_ev_range }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_battery_capacity }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_charging_rate_dc }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_charging_type_dc }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_charging_rate_ac }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_charging_type_ac }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_power_consumption }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_quick_charge_time }
            </div>
            {/* <div className={classNameSame}>
                { data.electric_motor_slow_charge_time }
            </div> */}
            <div className={classNameSame}>
                { data.electric_motor_battery_warranty }
            </div>
            <div className={classNameSame}>
                { data.electric_motor_feature }
            </div>
            <div className={classNameSame}>
                { data.transmission }
            </div>
            <div className={classNameSame}>
                { data.forward_ratio }
            </div>
            <div className={classNameSame}>
                { data.transmission_manufacture }
            </div>
            {/* <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.shift_mode} />
            </div> */}
            <div className={classNameSame}>
                { data.chassis_driven_wheel }
            </div>
            <div className={classNameSame}>
                { data.chassis_assembly }
            </div>
            <div className={classNameSame}>
                { data.chassis_type }
            </div>
            <div className={classNameSame}>
                { data.chassis_front_suspension }
            </div>
            <div className={classNameSame}>
                { data.chassis_rear_suspension }
            </div>
            <div className={classNameSame}>
                { data.chassis_adaptive_suspension }
            </div>
            <div className={classNameSame}>
                { !_.isEmpty(data.tyre_brand_name) ? <CustomLink href={pageLink.tyre.tyreMidUrl+data.tyre_brand_custom_url}>{data.tyre_brand_name}</CustomLink> : '-' }
            </div>
            <div className={classNameSame}>
                { !_.isEmpty(data.tyre_model_name) ? <CustomLink href={pageLink.tyre.tyreMidUrl+data.tyre_brand_custom_url+'/'+data.tyre_model_custom_url}>{data.tyre_model_name}</CustomLink> : '-' }
            </div>
            <div className={classNameSame}>
                { data.front_tyre }
            </div>
            <div className={classNameSame}>
                { data.front_wheels }
            </div>
            <div className={classNameSame}>
                { data.rear_tyre }
            </div>
            <div className={classNameSame}>
                { data.rear_wheels }
            </div>
            <div className={classNameSame}>
                {`${data.spare_tyre}${data.spare_wheels == '-' ? '' : " " + data.spare_wheels}`} 
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.tyre_pressure_monitor_system} />
            </div>
            <div className={classNameSame}>
                { data.front_brakes }
            </div>
            <div className={classNameSame}>
                { data.rear_brakes }
            </div>
            <div className={classNameSame}>
                { data.steering }
            </div>
            <div className={classNameSame}>
                { data.parking_brake }
            </div>
            <div className={classNameSame}>
            {
                data.euro_rating > 0 ?
                _.times(data.euro_rating, (index) => (
                    <IconStar key={index} color={'#FFC327'} />
                ))
                : "-"
            }
            </div>
            <div className={classNameSame}>
            {
                data.asean_rating > 0 ?
                _.times(data.asean_rating, (index) => (
                    <IconStar key={index} color={'#FFC327'} />
                ))
                : "-"
            }
            </div>
            <div className={classNameSame}>
                { data.airbag }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.driver_passenger_airbag} /> / <CheckOrCloseIcon isYes={data.front_passenger_airbag} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.front_side_airbag} /> / <CheckOrCloseIcon isYes={data.rear_side_airbag} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.front_curtain_airbag} /> / <CheckOrCloseIcon isYes={data.rear_curtain_airbag} />
            </div>
            <div className={classNameSame}>
                { data.knee_airbag }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.rear_airbag} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.airbag_disable} />
            </div>
            <div className={classNameSame}>
                { data.seatbelt_reminder }
            </div>
            {/* <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.emergency_braking} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.lane_keep_alert} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.blind_spot_system} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.collision_warning} />
            </div> */}
            <div className={classNameSame}>
                { data.child_safety_lock }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.isofix} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.security_alarm} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.immobilizer} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.abs_ebd} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.esc} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.traction_control_system} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.auto_brake_hold} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.emergency_braking} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.collision_warning} />
            </div>
            <div className={classNameSame}>
                {data.adaptive_cruise_control}
            </div>
            <div className={classNameSame}>
                {data.intelligent_cruise_control}
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.lane_keep_alert} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.lane_departure_warning} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.traffic_sign_info} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.blind_spot_system} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.rear_cross_traffic_alert} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.door_open_warning} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.parking_sensor_front} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.parking_sensor_rear} />
            </div>
            <div className={classNameSame}>
                { data.parking_camera }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.cruise_control} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.auto_parking} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.auto_start_stop} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.hill_start_assist} />
            </div>
            <div className={classNameSame}>
                { data.door_lock }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.folding_wing_mirror} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.auto_wiper} />
            </div>
            <div className={classNameSame}>
                { data.boot_operation }
            </div>
            <div className={classNameSame}>
                { data.soft_closing_door }
            </div>
            <div className={classNameSame}>
                { data.headlamp }
            </div>
            <div className={classNameSame}>
                { data.taillamp }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.drl} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.front_foglamp} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.rear_foglamp} />
            </div>
            <div className={classNameSame}>
                { data.interior_lighting }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.auto_headlamps} />
            </div>
            <div className={classNameSame}>
                { data.sunroof }
            </div>
            <div className={classNameSame}>
                { data.sunshade }
            </div>
            <div className={classNameSame}>
                { data.driver_seat_feature }
            </div>
            <div className={classNameSame}>
                { data.passenger_seat_feature }
            </div>
            <div className={classNameSame}>
                { data.rear_seat_feature }
            </div>
            <div className={classNameSame}>
                { data.cupholder }
            </div>
            <div className={classNameSame}>
                { data.front_aircon }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.rear_aircon} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.paddle_shift} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.multi_func_steering} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.steering_tilt} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.steering_telescopic} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.steering_adjustment} />
            </div>
            <div className={classNameSame}>
                { data.engine_start }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.headup_display} />
            </div>
            <div className={classNameSame}>
                { data.instrument_cluster }
            </div>
            <div className={classNameSame}>
                { data.screen }
            </div>
            <div className={classNameSame}>
                { data.screen_size }
            </div>
            <div className={classNameSame}>
                { data.navigation }
            </div>
            <div className={classNameSame}>
                { data.rear_entertainment }
            </div>
            <div className={classNameSame}>
                { data.bluetooth }
            </div>
            <div className={classNameSame}>
                { data.front_usb }
            </div>
            {/* <div className={classNameSame}>
                { data.aux }
            </div>
            <div className={classNameSame}>
                { data.cd }
            </div> */}
            <div className={classNameSame}>
                { data.rear_usb }
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.wireless_charger} />
            </div>
            <div className={classNameSame}>
                <CheckOrCloseIcon isYes={data.voice_command} />
            </div>
            <div className={classNameSame}>
                { data.apple_carplay }
            </div>
            <div className={classNameSame}>
                { data.android_auto }
            </div>
            <div className={classNameSame}>
                { data.car_app }
            </div>
            <div className={classNameSame}>
                { data.speaker_brand }
            </div>
            <div className={classNameSame}>
                { data.sound_plus_func }
            </div>
            <div className={classNameSame}>
                { data.speaker }
            </div>
            {
                // list out where package item
                data.package_count >= 1 && _.reduce(JSON.parse(data.package), (result, value, key)=>{
                    result.push(
                        <div key={key} className={classNameSame}>
                            { value }
                        </div>
                    )
                    return result
                },[])
            }
            {
                // list out where package item empty (take max varaint package row - current varaint package row = empty package row)
                (getPackageMaxTotal - data.package_count) >= 1 && Array.from({ length: (getPackageMaxTotal - data.package_count) }).map((_, i) => (
                    <div key={`package-${i}`} className={classNameSame}>
                        -
                    </div>
                ))
            }
        </>
    )
}

export const CompareResult = (props) => {
    const { title, containerRef, setCompareData, compareData, setVehicleBrandModalOpen = () => {}, isByModel = false, t } = props
    const [isSticky, setIsSticky] = useState(false)
    const compareVehicleContainerRef = useRef(null)
    const tableGridContainerRef = useRef(null)
    const { height, width} = useViewportSize();
    const { store, dispatch } = Redux();
    const [getPackageMaxTotal, setGetPackageMaxTotal] = useState(null)

    useEffect(()=>{
        const fetchData = async() => {
            if(store?.path?.dataPlacement == undefined){
                await _getPlacement(dispatch)
            }
            const totalPackageMax = _.chain(compareData)
            .maxBy('package_count')
            .get('package_count')
            .value() 
            setGetPackageMaxTotal(totalPackageMax == 0 ? 1 : totalPackageMax)
        }
        fetchData()
    },[compareData])

    // UI layout
    const handleScroll = () => {
        if(window.innerWidth <= mobileViewWidth){
            const windowScrollDistance = window?.scrollY || 0;

            if (windowScrollDistance >= 34 && !isSticky) {
                setIsSticky(true);
            } else if (windowScrollDistance < 34 && isSticky) {
                setIsSticky(false);
            }
        } else {
            const currentScrollDistance = containerRef.current?.scrollTop || 0;
            if (currentScrollDistance >= 34 && !isSticky) {
                setIsSticky(true);
            } else if (currentScrollDistance < 34 && isSticky) {
                setIsSticky(false);
            }
        }
    };

    useEffect(() => {
        const debouncedHandleScroll = _.debounce(handleScroll, 10);
    
        // responsive mobile desktop
        if(window.innerWidth <= mobileViewWidth){
            window.addEventListener('scroll', debouncedHandleScroll);

            return () => {
                window.removeEventListener('scroll', debouncedHandleScroll);
            };
        } else {
            // Attach scroll event listener to the container
            if (containerRef.current) {
                containerRef.current.addEventListener('scroll', debouncedHandleScroll);
            }
        
            // Clean up the event listener when the component unmounts
            return () => {
                if (containerRef.current) {
                    containerRef.current.removeEventListener('scroll', debouncedHandleScroll);
                }
            };
        }
        
    }, [isSticky])

    const handleDiv1Scroll = (e) => {
        if (containerRef.current) {
            const element = e.target;
            const currentScrollDistance = element.scrollLeft;
            compareVehicleContainerRef.current.scrollLeft = currentScrollDistance;
        }
    };
    
    const handleDiv2Scroll = (e) => {
        if (tableGridContainerRef.current) {
            const element = e.target;
            const currentScrollDistance = element.scrollLeft;
            tableGridContainerRef.current.scrollLeft = currentScrollDistance;
        }
    };
    // UI layout

    const _handleClearVehicle = (variant_id) => {
        const newItems = _.filter(compareData, (item) => item.variant_id !== variant_id);
        setCompareData(newItems)
        if(hasCookie('compare_list')){
            let data = JSON.parse(getCookie('compare_list'))
            const newItems = _.filter(data, (item) => item.variant_id !== variant_id);
            setCookie('compare_list', newItems)
        }
    }
    
    return (
        <>
            <p className="fontSize_16 fontWeight_bold containerPadding compareVehicleDetail_header_title">
                {t('compare_car_model')}
            </p>
            <div 
                className={"compareVehicleDetail_header"} 
                style={
                    isSticky == true 
                    ? (width <= mobileViewWidth 
                        ?  store?.path?.isLoadedInFlutterApp == true 
                            ?   {
                                    position: "fixed", 
                                    top: `0rem`,
                                    left: `0rem`,
                                    right: `0rem`,
                                    paddingTop: `1rem`
                                } 
                            :   {
                                    position: "fixed", 
                                    top: `6.4rem`,
                                    left: `0rem`,
                                    right: `0rem`,
                                    paddingTop: `1rem`
                                }
                        :   {
                                position: "fixed", 
                                top: `calc(50% - ${(containerRef?.current?.offsetHeight/2) - 64}px)`, 
                                left: `calc(50% - ${containerRef?.current?.offsetWidth/2}px)`, 
                                right: `calc(50% - ${containerRef?.current?.offsetWidth/2}px)`, 
                                width: containerRef?.current?.offsetWidth,
                                paddingTop: `${(containerRef?.current?.offsetWidth * 0.11) + 10}px`
                            }
                        )
                    :
                        {}
                }
            >
                <div onScroll={handleDiv2Scroll} ref={compareVehicleContainerRef} className="compareVehicleDetail_container">
                    <div className="compareVehicleDetail_vehicleList">
                        <div className="compareVehicleDetail_vehicleListWrap">
                            {
                                _.reduce(compareData,(result, value, key)=>{
                                    result.push(
                                            <div key={key} className="compareVehicleDetail_vehicleItem">
                                                <CustomLink href={`${pageLink.vehicle.vehicleMidUrl}${value?.brand_custom_url}/${value?.model_custom_url}/${value?.custom_url}`}>
                                                    <div className="compareVehicleDetail_vehicleItemBox cursorPointer">
                                                        <p className="fontSize_12 fontWeight_medium compareVehicleDetail_vehicleItem_text">
                                                            {value?.name}
                                                        </p>
                                                        <div className="compareVehicleDetail_vehicleItem_imageOuter">
                                                            <VehicleImage 
                                                                imagePath={!_.isEmpty(value?.variant_image_path) ? value?.variant_image_path : value?.model_image_path}
                                                            />
                                                        </div>
                                                    </div>
                                                </CustomLink>
                                                {
                                                    isByModel == false ?
                                                    <div onClick={()=>{_handleClearVehicle(value?.variant_id)}} className="compareVehicleDetail_vehicleItem_button cursorPointer">
                                                        <p className="fontSize_12 fontWeight_ semiBold fontColor_darkBlue2">
                                                            {t('clear')}
                                                        </p>
                                                    </div>
                                                    :
                                                    <div style={{height: '3rem'}} />
                                                }
                                            </div>
                                    )
                                    return result
                                },[])
                            }
                            {
                                (compareData.length <= 3 && isByModel == false) &&
                                <div onClick={()=>{setVehicleBrandModalOpen(true)}} className="compareVehicleDetail_vehicleItem compareVehicleDetail_vehicleItemEmpty cursorPointer">
                                    <div className="plusIcon_size">
                                        <img 
                                            className="w-100"
                                            src={"/assets/images/plug_lightBlue_icon.svg"}
                                        />
                                    </div>
                                    <p className="fontSize_14 fontColor_lightBlue">
                                        {t('add_model')}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="compareVehicleDetail_container">
                <div className="compareTableBox" style={isSticky == true ? {paddingTop: '22rem'} : {}}>
                    <div 
                        ref={tableGridContainerRef} 
                        onScroll={handleDiv1Scroll} 
                        className="compareTableGrid" 
                        // this is count total static row + package max row
                        style={{gridTemplateRows: `repeat(${getPackageMaxTotal + compareResultGridRow}, auto)`}}
                    >
                        {
                            _.reduce(specList, (result, value, key)=>{
                                result.push(
                                    <React.Fragment key={key}>
                                        <div 
                                            className={`compareTableGridHead compareTableGridCatHeader ${(store?.path?.dataPlacement?.[placementArea.compareResultPage_afterTyre]?.placement_id && key == "Tyre") && "compareTableGridCatHeaderHavePlacement"}`}
                                            style={{gridColumn: `span ${compareData?.length >= 1 ? (compareData.length + 1) : 2 }`}} // min 2, each length + 1 using grid
                                        >
                                            <div className="compareTableGridCatHeaderInnerBackground" style={{width: `${containerRef?.current?.offsetWidth}px`}}>
                                                <div className="fontSize_16 fontWeight_bold compareTableGridCatHeaderInner">
                                                    {key}
                                                </div>
                                            </div>
                                            {
                                                (store?.path?.dataPlacement?.[placementArea.compareResultPage_afterTyre]?.placement_id && key == "Tyre") &&
                                                <div className="compareTableGridCatHeaderHavePlacementPosition" style={{width: `${containerRef?.current?.offsetWidth}px`}}>
                                                    <div style={{width: '100%'}}>
                                                        <PlacementBanner 
                                                            loading={store?.path?.dataPlacement == undefined ? true : false}
                                                            area={placementArea?.compareResultPage_afterTyre} 
                                                            placement_id={store?.path?.dataPlacement?.[placementArea?.compareResultPage_afterTyre]?.placement_id}
                                                            type={store?.path?.dataPlacement?.[placementArea?.compareResultPage_afterTyre]?.type}
                                                            image_path={store?.path?.dataPlacement?.[placementArea?.compareResultPage_afterTyre]?.image_path}
                                                            url={store?.path?.dataPlacement?.[placementArea?.compareResultPage_afterTyre]?.url}
                                                            script={store?.path?.dataPlacement?.[placementArea?.compareResultPage_afterTyre]?.script}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {
                                            _.reduce(value, (result2, value2, key2)=>{
                                                result2.push(
                                                    <div key={`${key}-${key2}`} className="fontSize_14 compareTableGridHead compareTableGridHeader">
                                                        {value2}
                                                    </div>
                                                )
                                                return result2
                                            },[])
                                        }
                                        {
                                            // on package optional will show how list of package side
                                            key == "Package & Optional" && Array.from({ length: getPackageMaxTotal }).map((_, i) => (
                                                <div key={`${key}-package${i}`} className="fontSize_14 compareTableGridHead compareTableGridHeader">
                                                    { i + 1}
                                                </div>
                                            ))
                                        }
                                    </React.Fragment>
                                )
                                return result
                            },[])
                        }
                        {
                            _.reduce(compareData, (result, value, key)=>{
                                result.push(
                                    <GridColumn key={key} data={value} getPackageMaxTotal={getPackageMaxTotal} />
                                )
                                return result
                            },[])
                        }
                        {
                            (compareData.length <= 3 && isByModel == false) &&
                            <GridColumn getPackageMaxTotal={getPackageMaxTotal} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export const CompareBottomButton = (props)=>{
    const {compareList = [], handleOnClickGoCompare = ()=>{}, t} = props
    return (
        <div className="compareBottom containerPadding">
            <div onClick={()=>{handleOnClickGoCompare(compareList)}} className="compareBottom_button cursorPointer">
                <p className="fontSize_16 fontWeight_bold fontColor_white">
                    {t('compare')}
                </p>
                <div className="compareBottom_buttonRight">
                    <p className="fontSize_16 fontWeight_bold fontColor_red">
                        {compareList.length}
                    </p>
                </div>
            </div>
        </div>
    )
}