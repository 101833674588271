import { useState, useRef, useEffect } from "react"
import _ from "lodash"
import { CommonCategory } from '@/components/common/category';
import { FifthButton, FouthButton, VehicleModelTypeButton } from "../common/button";
import ScrollContainer from "react-indiana-drag-scroll";
import Link from "next/link";
import { pageLink } from '@/config/site';
import Image from "next/image";
import { ProfilePicture, VehicleImage } from "../common/image";
import { _stringToLink, _numToPriceBetween, _numToPrice, _modelName, _getTransmissionArray, _getMinMaxValueArrange } from "../../libraries/common";
import { useRouter } from "next/router";
import { toast } from "@/libraries/swal";
import { SkeletonBrandItem, SkeletonParagraph } from "../common/skeleton";
import { Skeleton } from "@mantine/core";
import { EmptyData } from "../common/emptyData";
import Redux, { updatePath } from "@/action";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { ExpendMore, MoreIcon } from "../common/icon";
import { colorBlueActiveCode, colorRedActiveCode } from "../../config/site";
import { VehicleSpecBox } from "./model";
import { CustomLink } from "../app/customLink";
import { removeIsWebviewPath } from '../../libraries/common';

export const VehicleOwnershipCostItem = (props) =>{
    const { label, text } = props
    return (
        <div className="vehicleModel_ownershipCostBox_row">
            <p className="fontSize_14 fontColor_grey2">
                {label}
            </p>
            <p className="fontSize_14 fontWeight_semiBold">
                {text}
            </p>
        </div>
    )
}

export const VehicleOwnershipCost = (props) =>{
    const { t, showHeader = true, showCompare = true, data, selfVaranitData = {}, isActiveVaraintList = [], handleOnClickCompare = () => {}, title } = props
    const router = useRouter()
    const { dispatch } = Redux();

    const _handleOnClickCalculator = (value) => {
        // show Compare false mean vehicle model page wont store redux go calculator page
        if(showCompare == true){
            dispatch(updatePath({ key: "calculatorCarLoad_object", path: value }));
        }
        router.push(pageLink.calculator.vehicleLoad);
    }

    return (
        <div className="vehicleModel_ownershipCost containerPadding">
            <div className="vehicleModel_ownershipCostBox">
                {
                    showHeader &&
                    <p className="fontSize_16 fontWeight_bold vehicleModel_ownershipCostBox_title">
                        {`${title} ${t('ownership_cost')}`}
                    </p>
                }
                <div className="vehicleModel_ownershipCostBoxInner">
                    <VehicleOwnershipCostItem label={t('road_tax_cost')} text={`${data?.min_road_tax > 0 ? _numToPrice(data.min_road_tax) + "/year" : "N/A"}`} />
                    <VehicleOwnershipCostItem label={t('insurance_cost')} text={`${data?.min_insurance_cost > 0 ? _numToPrice(data.min_insurance_cost) + "/year*" : "N/A"}`} />
                    {/* <VehicleOwnershipCostItem label={"Fuel Cost"} text={"RM3,233/year"} /> */}
                </div>
                <div className="vehicleModel_ownershipCostBox_buttonRow">
                    {
                        showCompare &&
                        <>
                        {
                            _.includes(isActiveVaraintList, selfVaranitData.variant_id) ?
                            <FifthButton
                                image={
                                    <div className="compareIconAdded_size">
                                        <BsFillCheckCircleFill 
                                            size={'100%'} 
                                            color={'#99D8EA'}
                                        />
                                    </div>
                                }
                                text={t("added")}
                                disabled={false}
                            />
                            :
                            <FouthButton 
                                handleOnclick={()=>handleOnClickCompare(selfVaranitData, "compare")}
                                image={
                                    <div className="compareIcon_size">
                                        <img
                                            className="w-100"
                                            src="/assets/images/compare_icon.svg"
                                        />
                                    </div>
                                }
                                text={t('compare')}
                            />
                        }
                        </>
                    }
                    <FouthButton 
                        handleOnclick={()=>_handleOnClickCalculator(data)}
                        image={
                            <div className="calculatorButtonIcon_size">
                                <img
                                    className="w-100"
                                    src="/assets/images/calculatorButton_icon.svg"
                                />
                            </div>
                        }
                        text={t('calculator')}
                    />
                </div>
            </div>
        </div>
    )
}

export const PopularCarBrands = (props) => {
    const { data = [], isSingleRow = false, t, willForceRefresh = false, loading = false } = props
    const router = useRouter()
    if(isSingleRow == true){
        if(loading == true){
            return (
                <div className={`popularCarBrands popularCarBrands_white`}>
                    <div className='popularCarBrands_title'>
                        <p className='fontSize_16 fontWeight_bold fontStyle_italic fontColor_white'>
                            {t('popular_brands_in_malaysia')}
                        </p>
                    </div>
                    <ScrollContainer 
                        className='popularCarBrandsList'
                        vertical={false}
                        horizontal={true}
                    >
                        <div className='popularCarBrandsListIniline'>
                            {
                                _.times(6, (key)=>(
                                    <SkeletonBrandItem key={key} />
                                ))
                            }
                        </div>
                    </ScrollContainer>
                </div>
            )
        }
        return (
            <>
                {
                    !_.isEmpty(data) &&
                    <div className={`popularCarBrands ${isSingleRow == true && "popularCarBrands_white"}`}>
                        <div className='popularCarBrands_title'>
                            <p className='fontSize_16 fontWeight_bold fontStyle_italic fontColor_white'>
                                {t('popular_brands_in_malaysia')}
                            </p>
                        </div>
                        <ScrollContainer 
                            className='popularCarBrandsList'
                            vertical={false}
                            horizontal={true}
                        >
                            <div className='popularCarBrandsListIniline'>
                                {
                                    _.reduce(_.take(_.orderBy(data, ['id'], ['desc']), 10), (result, value, key) => {
                                        result.push(
                                            <CustomLink key={key} href={`${pageLink.vehicle.vehicleMidUrl + value.brand_custom_url}`}>
                                                <div className='popularCarBrandsItem cursorPointer'>
                                                    <div className='popularCarBrandsItem_imageOuter'>
                                                        <div className="popularCarBrandsItem_image" style={{backgroundImage: `url(${process.env.NEXT_PUBLIC_FS_URL + value.image_path})`}} />
                                                    </div>
                                                    <p className='fontSize_14 popularCarBrandsItem_text'>
                                                        {value.name}
                                                    </p>
                                                </div>
                                            </CustomLink>
                                        )
                                        return result
                                    },[])
                                }
                            </div>
                        </ScrollContainer>
                    </div>
                }
            </>
            
        )
    }
    if(loading == true){
        return (
            <div className='popularCarBrands popularCarBrands2'>
                <div className='popularCarBrands_title'>
                    <p className='fontSize_16 fontWeight_bold fontStyle_italic fontColor_white'>
                        {t('popular_brands_in_malaysia')}
                    </p>
                </div>
                <div className="popularCarBrands2_list">
                    {
                        _.times(10, (key)=>(
                            <div key={key} className='popularCarBrandsItem cursorPointer'>
                                <SkeletonBrandItem />
                            </div>
                        ))
                    }
                </div>
                <CustomLink href={`${pageLink.vehicle.homepage}`}>
                    <div className="popularCarBrands2_viewmore">
                        <p className="fontSize_14 fontWeight_semiBold fontColor_red">
                            {t('view_more')}
                        </p>
                        <div className="viewMore_icon">
                            <ExpendMore color={colorBlueActiveCode} />
                        </div>
                    </div>
                </CustomLink>
            </div>
        )
    }
    return (
        <>
            {
                !_.isEmpty(data) &&
                <div className='popularCarBrands popularCarBrands2'>
                    <div className='popularCarBrands_title'>
                        <p className='fontSize_16 fontWeight_bold fontStyle_italic fontColor_white'>
                            {t('popular_brands_in_malaysia')}
                        </p>
                    </div>
                    <div className="popularCarBrands2_list">
                        {
                            _.reduce(_.take(_.orderBy(data, ['id'], ['desc']), 10), (result, value, key) => {
                                result.push(
                                    <CustomLink key={key} href={`${pageLink.vehicle.vehicleMidUrl + value.brand_custom_url}`}>
                                        <div className='popularCarBrandsItem cursorPointer'>
                                            <div className='popularCarBrandsItem_imageOuter'>
                                                <div className="popularCarBrandsItem_image" style={{backgroundImage: `url(${process.env.NEXT_PUBLIC_FS_URL + value.image_path})`}} />
                                            </div>
                                            <p className='fontSize_14 popularCarBrandsItem_text'>
                                                {value.name}
                                            </p>
                                        </div>
                                    </CustomLink>
                                )
                                return result
                            },[])
                        }
                    </div>
                    <CustomLink href={`${pageLink.vehicle.homepage}`}>
                        <div className="popularCarBrands2_viewmore cursorPointer">
                            <p className="fontSize_14 fontWeight_semiBold fontColor_red">
                                {t('view_more')}
                            </p>
                            <div className="viewMore_icon">
                                <ExpendMore color={colorRedActiveCode} />
                            </div>
                        </div>
                    </CustomLink>
                </div>
            }
        </>
    )
}

export const VehicleBrandList = (props) => {
    const { data = [], showSideBar = false, handleIndexItemClick = () => {}, handleClickBrandItem = () => {}, activeList = [], loading = false, t } = props
    const [modalWidthSize, setModalWidthSize] = useState(null)
    const modalVehicleBrandsWidthRef = useRef(null)
    const contactSections = useRef({});

    useEffect(()=>{
        if(showSideBar == true && modalWidthSize == null && modalVehicleBrandsWidthRef?.current?.offsetWidth > 0){
            setModalWidthSize(modalVehicleBrandsWidthRef?.current?.offsetWidth)
        }
    },[])

    if(loading == true){
        return (
            <div className='vehiclePage_vehicle_brandlistMargin'>
                <div className="vehicle_brandlistContainer">
                    <div className={"vehicle_brandlist"}>
                        {
                            _.times(3, (key)=>(
                                <div key={key}>
                                    <div className='vehicle_brandlist_titlebox containerPadding'>
                                        <div style={{width: '15rem'}}>
                                            <SkeletonParagraph row={1} isTitle={true} />
                                        </div>
                                    </div>
                                    {
                                        _.times(3, (key2)=>(
                                            <div key={key2} className='containerPadding'>
                                                <div className='vehicle_brandlist_rowBox'>
                                                    <Skeleton height={'4rem'} width={'4rem'} circle />
                                                    <div style={{width: '20rem', maxWidth: '100%'}}>
                                                        <SkeletonParagraph row={1}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(_.isEmpty(data)){
        return (
            <EmptyData t={t} />
        )
    }

    let organizedData = _.chain(data)
    .groupBy(brand => brand.name.charAt(0).toUpperCase())
    .toPairs()
    .orderBy(['0'], ['asc'])
    .map(pair => ({
        key: pair[0],
        values: _.orderBy(pair[1], ['name'], ['asc']) 
    }))
    .value()

    

    return (
        <div className="vehicle_brandlistContainer">
            {
                (showSideBar && modalWidthSize > 0) &&
                <div className="vehicle_brandlist_sideBarOuter" style={{right: `calc(50% - ${modalWidthSize/2}px)`}}>
                    <div className="vehicle_brandlist_sideBarVisible vehicle_brandlist_sideBarVisibleTop" />
                    <div className="vehicle_brandlist_sideBarVisible vehicle_brandlist_sideBarVisibleBottom" />
                    <div className="vehicle_brandlist_sideBar">
                        {
                            !_.isEmpty(organizedData) &&
                            _.reduce(organizedData, (result, value, key)=>{
                                result.push(
                                    <div onClick={()=>handleIndexItemClick(value.key, contactSections)} key={key} className="vehicle_brandlist_sideBarItem cursorPointer">
                                        <p className="fontSize_14 fontWeight_medium fontColor_red" style={{textAlign: 'center', lineHeight: 'normal'}}>
                                            {value.key}
                                        </p>
                                    </div>
                                )
                                return result
                            },[])
                        }
                    </div>
                </div>
            }
            
            <div ref={modalVehicleBrandsWidthRef} className={showSideBar == true ? 'vehicle_brandlist vehicle_brandlist_ifGotSideBar' : "vehicle_brandlist"}>
                {
                    _.reduce(organizedData, (result, value)=>{
                        result.push(
                            <div ref={(ref) => (contactSections.current[value.key] = ref)} key={value.key}>
                                <div className='vehicle_brandlist_titlebox containerPadding'>
                                    <p className='fontSize_16 fontWeight_medium'>
                                        {value.key}
                                    </p>
                                </div>
                                {
                                    _.reduce(value.values, (result2, value2, key2)=>{
                                        result2.push(
                                            <div onClick={()=>{handleClickBrandItem(value2)}} key={key2} className='containerPadding cursorPointer'>
                                                <div className='vehicle_brandlist_rowBox'>
                                                    <div className='vehicle_brandlist_iconOutner'>
                                                        <div className='vehicle_brandlist_icon' style={{backgroundImage: `url(${process.env.NEXT_PUBLIC_FS_URL + value2?.image_path})`}} />
                                                    </div>
                                                    <p className={`fontSize_14 ${!_.isEmpty(activeList) ? _.includes(activeList, value2.brand_custom_url) ? 'fontColor_red' : undefined : undefined}`}>
                                                        {value2.name}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                        return result2
                                    },[])
                                }
                            </div>
                        )
                        return result
                    },[])
                }
            </div>
        </div>
    )
}

export const VehicleModelVariant = (props) => {
    const { isSwitchAllow = true, isGalleryAllow = true, t, imagePath, setVehicleVariantModalOpen = () => {}, isProfileVehicle = false } = props
    const router = useRouter()

    return (
        <div className="vehicleModel_vehicleHeader">
            <div className="vehicleModel_vehicleHeaderImageOuter">
                {
                    !_.isEmpty(imagePath) ?
                    <img 
                        className="w-100"
                        src={`${process.env.NEXT_PUBLIC_FS_URL + imagePath}`}
                    />
                    :
                    isProfileVehicle == true ?
                        <div className="vehicleModel_vehicleHeaderImageEmpty">
                            <div className='d_flex cursorPointer' style={{width: '25rem'}}>
                                <img className='w-100' src="/assets/images/logo.png" />
                            </div>
                        </div>
                    :
                        <div className="vehicleModel_vehicleHeaderImageEmpty">
                            <p className="fontSize_28 fontStyle_italic fontWeight_extraBold fontColor_lightGrey3">
                                COMING SOON
                            </p>
                        </div>
                }
            </div>
            <div className="vehicleModel_buttonRow containerPadding">
                {
                    isSwitchAllow == true ? 
                    <div onClick={()=>setVehicleVariantModalOpen(true)} className="switchButton cursorPointer">
                        <div className="switchButton_image">
                            <img 
                                className="w-100"
                                src={"/assets/images/switch_icon.png"}
                            />
                        </div>
                        <p className="fontSize_12 fontWeight_semiBold fontColor_white">
                            {t('switch')}
                        </p>
                    </div>
                    :
                    <div></div>
                }
                {
                    isGalleryAllow == true && 
                    <CustomLink href={`${removeIsWebviewPath(router.asPath)}/gallery`}>
                        <div className="imageButton cursorPointer">
                            <div className="imageButton_image">
                                <img 
                                    className="w-100"
                                    src={"/assets/images/image_icon.svg"}
                                />
                            </div>
                            <p className="fontSize_12 fontWeight_semiBold fontColor_darkBlue">
                                {t('view_gallery')}
                            </p>
                        </div>
                    </CustomLink>
                }
            </div>
        </div>
    )
}

 export const VehicleReviewAuthor = (props) =>{
    const {title, data, t, loading = false} = props
    const router = useRouter()

    if(loading == true){
        return (
            <div className="vehicleReview containerPadding">
                <p className="fontSize_16 fontWeight_bold">
                    {`${title} ${t('review_author')}`}
                </p>
                <div className="vehicleReview_list">
                    {
                        _.times(6, (key)=>(
                            <div key={key} className="vehicleReview_item">
                                <div className="vehicleReview_item_imageOuter">
                                    <div className="vehicleReview_item_image">
                                        <div className="absoluteSkeletonBox">
                                            <Skeleton height={'100%'} circle />
                                        </div>
                                    </div>
                                </div>
                                <SkeletonParagraph row={1} isTitle />
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
    return (
        <>
            {
                !_.isEmpty(data) &&
                <div className="vehicleReview containerPadding">
                    <p className="fontSize_16 fontWeight_bold">
                        {`${title} ${t('review_author')}`}
                    </p>
                    <div className="vehicleReview_list">
                        {
                            _.reduce(data, (result, value, key)=>{
                                result.push(
                                    <CustomLink key={key} href={`${router.query.vehicle[0]}/${router.query.vehicle[1]}/author/${value.user_id}`}>
                                        <div className="vehicleReview_item cursorPointer">
                                            <div className="vehicleReview_item_imageOuter">
                                                <ProfilePicture 
                                                    imagePath={!_.isEmpty(value.profile_image) ? process.env.NEXT_PUBLIC_FS_URL + value.profile_image : undefined}
                                                    size={6.2}
                                                />
                                            </div>
                                            <p className="fontSize_14">
                                                {value.full_name}
                                            </p>
                                        </div>
                                    </CustomLink>
                                )
                                return result
                            },[])
                        }
                    </div>
                </div>
            }
        </>
    )
}

export const VariantSeriesSale = (props) => {
    const { t, showHeader = false, data = [], handleOnClickCompare = () => {}, isActiveVaraintList = [], title, loading = false } = props
    // category
    const [activeCategory, setActiveCategory] = useState("Yes")
    const [isViewMore, setIsViewMore] = useState(false)
    const router = useRouter()
    const { dispatch } = Redux();

    useEffect(()=>{
        if(!_.isEmpty(data?.["Yes"])){
            setActiveCategory("Yes")
        } else if (!_.isEmpty(data?.["Upcoming"])) {
            setActiveCategory("Upcoming")
        } else {
            setActiveCategory("No")
        }
    },[data])

    if(loading == true){
        return (
            <div className="vehicleModel_SeriesSale">
                <div className="vehicleModel_divideLine" />
                {
                    showHeader &&
                    <div className="containerPadding vehicleModel_SeriesSale_title">
                        <p className="fontSize_16 fontWeight_bold">
                            {`${title} ${t('price_list_variants')}`} 
                        </p>
                    </div>
                }
                <CommonCategory 
                    // data={[]}
                    // activeCategory={activeCategory}
                    // setActiveCategory={_handleOnClickChangeCategory}
                    t={t}
                    isAllAllow={false}
                    loading={true}
                />
                <div className="vehicleModel_SeriesSale_content containerPadding">
                    <div className="vehicleModel_SeriesSale_contentItem">
                        <div className="vehicleModel_SeriesSale_contentItem_header">
                            <div className="fontSize_14 fontColor_blue">
                                <SkeletonParagraph row={1} isTitle />
                            </div>
                        </div>
                        <div className="vehicleModel_SeriesSale_contentItem_body">
                            {
                                _.times(3,(key)=>(
                                    <div key={`${key}`} className="vehicleModel_SeriesSale_contentItem_bodyRow">
                                        <div>
                                            <div className="fontSize_16 fontWeight_semiBold">
                                                <SkeletonParagraph row={1} isTitle />
                                            </div>
                                            <div className="fontSize_16 fontWeight_semiBold fontColor_lightBlue vehicleModel_SeriesSale_contentItem_bodyRow_price">
                                                <SkeletonParagraph row={1} isTitle />
                                            </div>
                                            <div className="fontSize_12 fontColor_lightGrey3">
                                                <SkeletonParagraph row={1} />
                                            </div>
                                        </div>
                                        <div className="vehicleModel_SeriesSale_contentItem_bodyButtonRow">
                                            <div className="fouthButton positionRelative">
                                                <div className="absoluteSkeletonBox">
                                                    <Skeleton height={'100%'} radius={'5rem'} />
                                                </div>
                                            </div>
                                            <div className="fouthButton positionRelative">
                                                <div className="absoluteSkeletonBox">
                                                    <Skeleton height={'100%'} radius={'5rem'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(_.isEmpty(data)){
        return <></>
    }

    let variantOnSaleLimitStart = 1
    let vehicleSeries = []
    if(!_.isEmpty(data?.["Yes"])){
        vehicleSeries.push({id: "Yes", text: t("on_sale")})
    }
    if(!_.isEmpty(data?.["No"])){
        vehicleSeries.push({id: "No", text: t("not_on_sale")})
    }
    if(!_.isEmpty(data?.["Upcoming"])){
        vehicleSeries.push({id: "Upcoming", text: t("upcoming")})
    }

    const _handleOnClickChangeCategory = (value) => {
        setActiveCategory(value)
        if(isViewMore == true){
            setIsViewMore(false)
        }
    }

    const _handleOnClickCalculator = (value) => {
        dispatch(updatePath({ key: "calculatorCarLoad_object", path: value }));
        router.push(pageLink.calculator.vehicleLoad);
    }

    return (
        <div className="vehicleModel_SeriesSale">
            <div className="vehicleModel_divideLine" />
            {
                showHeader &&
                <div className="containerPadding vehicleModel_SeriesSale_title">
                    <p className="fontSize_16 fontWeight_bold">
                        {`${title} ${t('price_list_variants')}`} 
                    </p>
                </div>
            }
            <CommonCategory 
                data={vehicleSeries}
                activeCategory={activeCategory}
                setActiveCategory={_handleOnClickChangeCategory}
                t={t}
                isAllAllow={false}
            />
            <div className="vehicleModel_SeriesSale_content containerPadding">
                {
                    _.reduce(data?.[activeCategory], (result, value, key)=>{
                        if(variantOnSaleLimitStart <= 3 || isViewMore == true ){
                            result.push(
                                <div key={key} className="vehicleModel_SeriesSale_contentItem">
                                    <div className="vehicleModel_SeriesSale_contentItem_header">
                                        <p className="fontSize_14 fontColor_blue">
                                            {value.series_name}
                                        </p>
                                    </div>
                                    <div className="vehicleModel_SeriesSale_contentItem_body">
                                        {
                                            _.reduce(value.list, (result2, value2, key2)=>{
                                                if(variantOnSaleLimitStart <= 3 || isViewMore == true ){
                                                    variantOnSaleLimitStart++
                                                    result2.push(
                                                            <div key={`${key}-${key2}`} className="vehicleModel_SeriesSale_contentItem_bodyRow">
                                                                <CustomLink  href={`${router.query.vehicle[0]}/${router.query.vehicle[1]}/${value2.variant_custom_url}`}>
                                                                    <div className="cursorPointer">
                                                                        <p className="fontSize_16 fontWeight_semiBold">
                                                                            {value2.name}
                                                                        </p>
                                                                        <p className="fontSize_16 fontWeight_semiBold fontColor_lightBlue vehicleModel_SeriesSale_contentItem_bodyRow_price">
                                                                            {_numToPrice(value2.price)}
                                                                        </p>
                                                                        <p className="fontSize_12 fontColor_lightGrey3">
                                                                            {`${_numToPrice(value2.monthly_payment)} /month`}
                                                                        </p>
                                                                    </div>
                                                                </CustomLink>
                                                                <div className="vehicleModel_SeriesSale_contentItem_bodyButtonRow">
                                                                    {
                                                                        _.includes(isActiveVaraintList, value2.variant_id) ?
                                                                            <FifthButton
                                                                                image={
                                                                                    <div className="compareIconAdded_size">
                                                                                        <BsFillCheckCircleFill 
                                                                                            size={'100%'} 
                                                                                            color={'#99D8EA'}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                text={t("added")}
                                                                                disabled={false}
                                                                            />
                                                                            :
                                                                            <FouthButton 
                                                                                handleOnclick={()=>handleOnClickCompare(value2, "compare")}
                                                                                image={
                                                                                    <div className="compareIcon_size">
                                                                                        <img
                                                                                            className="w-100"
                                                                                            src="/assets/images/compare_icon.svg"
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                text={t('compare')}
                                                                            />
                                                                    }
                                                                    <FouthButton 
                                                                        // handleOnclick={()=>handleOnClickCompare(value2, "calculator")}
                                                                        handleOnclick={()=>_handleOnClickCalculator(value2)}
                                                                        image={
                                                                            <div className="calculatorButtonIcon_size">
                                                                                <img
                                                                                    className="w-100"
                                                                                    src="/assets/images/calculatorButton_icon.svg"
                                                                                />
                                                                            </div>
                                                                        }
                                                                        text={t('calculator')}
                                                                    />
                                                                </div>
                                                            </div>
                                                    )
                                                }
                                                return result2
                                            }, [])
                                        }
                                    </div>
                                </div>
                            )
                        }
                        return result
                    },[])
                }
            </div>
            {
                isViewMore == false && _.sumBy(data?.[activeCategory], (item) => _.get(item, 'list.length', 0)) >= 4 &&
                <div onClick={()=>{setIsViewMore(true)}} className="vehicleModel_SeriesSale_button containerPadding">
                    <div className="viewMore cursorPointer">
                        <p className='fontSize_14 fontWeight_semiBold fontColor_blue'>
                            {t('view_more')}
                        </p>
                        <div className="viewMore_icon">
                            <ExpendMore color={colorBlueActiveCode} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export const VehicleListByBrand = (props) =>{
    const { data = [], t, loading = false } = props
    if(loading == true){
        return (
            <div className='vehicleBrands_hotUpcoming'>
                {
                    _.times(3, (key)=>(
                        <div key={key} className='vehicleBrands_hotUpcoming_item containerPadding'>
                            <div className='vehicleBrands_hotUpcoming_itemBox'>
                                <div className='vehicleBrands_hotUpcoming_itemBox_content'>
                                    {/* <div className='vehicleBrands_hotUpcoming_itemBox_content_top'>
                                        <VehicleModelTypeButton t={t} type={value?.type} />
                                    </div> */}
                                    <div className='vehicleBrands_hotUpcoming_itemBox_content_bottom'>
                                        <div className='fontWeight_semiBold fontColor_blue vehicleBrands_hotUdivcoming_itemBox_content_bottom_title'>
                                            <SkeletonParagraph row={1} isTitle/>
                                        </div>
                                        <div className='fontSize_14 fontWeight_semiBold fontColor_lightBlue vehicleBrands_hotUpcoming_itemBox_content_bottom_row_text'>
                                            <SkeletonParagraph row={1} isTitle/>
                                        </div>
                                    </div>
                                    <div className='fontSize_12 fontColor_lightBlue2 vehicleBrands_hotUpcoming_itemBox_content_bottom_row_text2'>
                                        <SkeletonParagraph row={1}/>
                                    </div>
                                </div>
                                <div className='vehicleBrands_hotUpcoming_itemBox_image'>
                                    <div className="vehicleBrands_hotUpcoming_itemBox_imageInner">
                                        <VehicleImage 
                                            loading={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }
    if(_.isEmpty(data)){
        return (
            <EmptyData t={t} />
        )
    }
    return(
        <div className='vehicleBrands_hotUpcoming'>
            {
                _.reduce(data, (result, value, key)=>{
                    result.push(
                        <CustomLink key={key} href={`${pageLink.vehicle.vehicleMidUrl + value.brand_custom_url + "/" + value.model_custom_url}`} >
                            <div className='vehicleBrands_hotUpcoming_item containerPadding cursorPointer'>
                                <div className='vehicleBrands_hotUpcoming_itemBox'>
                                    <div className='vehicleBrands_hotUpcoming_itemBox_content'>
                                        {/* <div className='vehicleBrands_hotUpcoming_itemBox_content_top'>
                                            <VehicleModelTypeButton t={t} type={value?.type} />
                                        </div> */}
                                        <div className='vehicleBrands_hotUpcoming_itemBox_content_bottom'>
                                            <p className='fontWeight_semiBold fontColor_blue vehicleBrands_hotUpcoming_itemBox_content_bottom_title'>
                                                {value?.name}
                                            </p>
                                            <p className='fontSize_14 fontWeight_semiBold fontColor_lightBlue vehicleBrands_hotUpcoming_itemBox_content_bottom_row_text'>
                                                {_numToPriceBetween(JSON.parse(value?.variant_object)?.min_price, JSON.parse(value?.variant_object)?.max_price)}
                                            </p>
                                        </div>
                                        <p className='fontSize_12 fontColor_lightBlue2 vehicleBrands_hotUpcoming_itemBox_content_bottom_row_text2'>
                                            {`${value.body_type} | ${value.segment}`}
                                        </p>
                                    </div>
                                    <div className='vehicleBrands_hotUpcoming_itemBox_image'>
                                        <div className="vehicleBrands_hotUpcoming_itemBox_imageInner">
                                            <VehicleImage 
                                                imagePath={value.image_path}
                                                tagType={value.type}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CustomLink>
                    )
                    return result
                },[])
            }
        </div>
    )
}

export const RecommendedCars = (props) => {
    const { data = [], t, loading = false } = props
    if(loading == true){
        return (
            <div className='recommendedCars'>
                <div className='recommendedCars_title'>
                    <p className='fontSize_16 fontWeight_bold fontStyle_italic fontColor_white'>
                        {t('recommended_cars')}
                    </p>
                </div>
                <ScrollContainer 
                    className='recommendedCarsList'
                    vertical={false}
                    horizontal={true}
                >
                    <div className='recommendedCarsListIniline'>
                        {
                            _.times(3, (key)=>(
                                <div key={key} className='recommendedCarsItem cursorPointer'>
                                    <div className='recommendedCarsItem_imagePosition'>
                                        <VehicleImage loading={true}/>
                                    </div>
                                    <SkeletonParagraph row={3} isTitle={true} />
                                </div>
                            ))
                        }
                    </div>
                </ScrollContainer>
            </div>
        )
    }
    return (
        <>
            {
                !_.isEmpty(data) &&
                <div className='recommendedCars'>
                    <div className='recommendedCars_title'>
                        <p className='fontSize_16 fontWeight_bold fontStyle_italic fontColor_white'>
                            {t('recommended_cars')}
                        </p>
                    </div>
                    <ScrollContainer 
                        className='recommendedCarsList'
                        vertical={false}
                        horizontal={true}
                    >
                        <div className='recommendedCarsListIniline'>
                            {
                                _.reduce(data, (result, value, key) => {
                                    result.push(
                                        <CustomLink key={key} href={`${pageLink.vehicle.vehicleMidUrl + value.brand_custom_url + "/" + value.model_custom_url}`}>
                                            <div className='recommendedCarsItem cursorPointer'>
                                                <div className='recommendedCarsItem_imagePosition'>
                                                    <VehicleImage 
                                                        imagePath={value.image_path}
                                                    />
                                                </div>
                                                <p className='fontSize_12 fontColor_lightBlue'>
                                                    {value.vehicle_brand_name}
                                                </p>
                                                <p className='fontSize_12 fontWeight_semiBold fontColor_blue'>
                                                    {_modelName(value.vehicle_brand_name, value.name)}
                                                </p>
                                                <p className='fontSize_12 fontWeight_semiBold fontColor_lightBlue'>
                                                    {_numToPrice(value.min_price)}
                                                </p>
                                            </div>
                                        </CustomLink>
                                    )
                                    return result
                                },[])
                            }
                        </div>
                    </ScrollContainer>
                </div>
            }
        </>
    )
}

export const RelatedModel = (props) => {
    const {vehicleModelList, t, loading = false, isSimilar = false} = props
    if(loading == true){
        return (
            <div className='newsDetail_relatedModel'>
                <p className='fontSize_16 fontWeight_semiBold containerPadding'>
                    {isSimilar == true ? t('similar_vehicle') : t('related_model')}
                </p>
                <div className='newsDetail_relatedModel_scrollContainer'>
                    <div className='newsDetail_relatedModel_wrap containerPadding' style={{width: '100%'}}>
                        <div className='newsDetail_relatedModel_box' style={{width: '100%', minWidth: 'unset', maxWidth: 'unset'}}>
                            <div className='newsDetail_relatedModel_header'>
                                <div className='newsDetail_relatedModel_header_image'>
                                    <VehicleImage loading={true} />
                                </div>
                                <div className='newsDetail_relatedModel_header_text'>
                                    <SkeletonParagraph row={2} isTitle={true} />
                                </div>
                            </div>
                            <div className='newsDetail_relatedModel_infoContainer'>
                                <div className='newsDetail_relatedModel_infoBox'>
                                    <p className='fontSize_14 fontColor_lightGrey2'>
                                        {t('body_type')}
                                    </p>
                                    <div className='fontSize_14 fontWeight_medium'>
                                        <SkeletonParagraph row={1} isTitle={true} />
                                    </div>
                                </div>
                                <div className='newsDetail_relatedModel_infoBox'>
                                    <p className='fontSize_14 fontColor_lightGrey2'>
                                        {t('capacity')}
                                    </p>
                                    <div className='fontSize_14 fontWeight_medium'>
                                        <SkeletonParagraph row={1} isTitle={true} />
                                    </div>
                                </div>
                                <div className='newsDetail_relatedModel_infoBox'>
                                    <p className='fontSize_14 fontColor_lightGrey2'>
                                        {t('transmission')}
                                    </p>
                                    <div className='fontSize_14 fontWeight_medium'>
                                        <SkeletonParagraph row={1} isTitle={true} />
                                    </div>
                                </div>
                                <div className='newsDetail_relatedModel_infoBox'>
                                    <p className='fontSize_14 fontColor_lightGrey2'>
                                        {t('horsepower')}
                                    </p>
                                    <div className='fontSize_14 fontWeight_medium'>
                                        <SkeletonParagraph row={1} isTitle={true} />
                                    </div>
                                </div>
                            </div>
                            <div className='newsDetail_relatedModel_bottom'>
                                <div className='newsDetail_relatedModel_bottomInner'>
                                    <SkeletonParagraph row={1} isTitle={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {
                !_.isEmpty(vehicleModelList) && 
                <div className='newsDetail_relatedModel'>
                    <p className='fontSize_16 fontWeight_semiBold containerPadding'>
                        {isSimilar == true ? t('similar_vehicle') : t('related_model')}
                    </p>
                    <div className='newsDetail_relatedModel_scrollContainer'>
                        <div className='newsDetail_relatedModel_wrap containerPadding' style={{width: vehicleModelList.length == 1 ? '100%' : 'unset'}}>
                            {
                                _.reduce(vehicleModelList, (result, value, key)=>{
                                    result.push(
                                        <CustomLink key={key} href={pageLink.vehicle.vehicleMidUrl + value.brand_custom_url + '/' + value.model_custom_url}>
                                            <div className='newsDetail_relatedModel_box cursorPointer' style={vehicleModelList.length == 1 ? {width:  '100%', minWidth: 'unset', maxWidth: 'unset'} : {}}>
                                                <div className='newsDetail_relatedModel_header'>
                                                    <div className='newsDetail_relatedModel_header_image'>
                                                        <VehicleImage 
                                                            imagePath={value.image_path}
                                                        />
                                                    </div>
                                                    <div className='newsDetail_relatedModel_header_text'>
                                                        <p className='fontSize_14 fontWeight_semiBold'>
                                                            {value.name}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='newsDetail_relatedModel_infoContainer'>
                                                    <div className='newsDetail_relatedModel_infoBox'>
                                                        <p className='fontSize_14 fontColor_lightGrey2'>
                                                            {t('body_type')}
                                                        </p>
                                                        <p className='fontSize_14 fontWeight_medium'>
                                                            {`${value.doors > 0 ? value.doors+"-door " : ""}`}{`${value.body_type}`}
                                                        </p>
                                                    </div>
                                                    {
                                                        value?.fuel == "EV" ?
                                                        <div className='newsDetail_relatedModel_infoBox'>
                                                            <p className='fontSize_14 fontColor_lightGrey2'>
                                                                {t('ev_range')}
                                                            </p>
                                                            <p className='fontSize_14 fontWeight_medium'>
                                                                {`${_getMinMaxValueArrange(JSON.parse(value?.variant_object)?.min_ev_range, JSON.parse(value?.variant_object)?.max_ev_range)} km`}
                                                            </p>
                                                        </div>
                                                        :
                                                        <div className='newsDetail_relatedModel_infoBox'>
                                                            <p className='fontSize_14 fontColor_lightGrey2'>
                                                                {t('capacity')}
                                                            </p>
                                                            <p className='fontSize_14 fontWeight_medium'>
                                                                {`${_getMinMaxValueArrange(JSON.parse(value?.variant_object)?.min_capacity, JSON.parse(value?.variant_object)?.max_capacity, 1)} L`}
                                                            </p>
                                                        </div>
                                                    }
                                                    {/* <div className='newsDetail_relatedModel_infoBox'>
                                                        <p className='fontSize_14 fontColor_lightGrey2'>
                                                            {t('capacity')}
                                                        </p>
                                                        <p className='fontSize_14 fontWeight_medium'>
                                                            {`${_getMinMaxValueArrange(JSON.parse(value?.variant_object)?.min_capacity, JSON.parse(value?.variant_object)?.max_capacity, 1)} L`}
                                                        </p>
                                                    </div> */}
                                                    <div className='newsDetail_relatedModel_infoBox'>
                                                        <p className='fontSize_14 fontColor_lightGrey2'>
                                                            {t('transmission')}
                                                        </p>
                                                        <p className='fontSize_14 fontWeight_medium'>
                                                            {
                                                                _getTransmissionArray(JSON.parse(value?.variant_object)?.forward_ratio)
                                                            }
                                                        </p>
                                                    </div>
                                                    {
                                                        value?.fuel == "EV" ?
                                                        <div className='newsDetail_relatedModel_infoBox'>
                                                            <p className='fontSize_14 fontColor_lightGrey2'>
                                                                {t('horsepower')}
                                                            </p>
                                                            <p className='fontSize_14 fontWeight_medium'>
                                                                {`${_getMinMaxValueArrange(JSON.parse(value?.variant_object)?.min_electric_horsepower, JSON.parse(value?.variant_object)?.max_electric_horsepower)} PS`}
                                                            </p>
                                                        </div>
                                                        :
                                                        <div className='newsDetail_relatedModel_infoBox'>
                                                            <p className='fontSize_14 fontColor_lightGrey2'>
                                                                {t('horsepower')}
                                                            </p>
                                                            <p className='fontSize_14 fontWeight_medium'>
                                                                {`${_getMinMaxValueArrange(JSON.parse(value?.variant_object)?.min_horsepower, JSON.parse(value?.variant_object)?.max_horsepower)} PS`}
                                                            </p>
                                                        </div>
                                                    }
                                                    {/* <div className='newsDetail_relatedModel_infoBox'>
                                                        <p className='fontSize_14 fontColor_lightGrey2'>
                                                            {t('horsepower')}
                                                        </p>
                                                        <p className='fontSize_14 fontWeight_medium'>
                                                            {`${_getMinMaxValueArrange(JSON.parse(value?.variant_object)?.min_horsepower, JSON.parse(value?.variant_object)?.max_horsepower)} hp`}
                                                        </p>
                                                    </div> */}
                                                </div>
                                                <div className='newsDetail_relatedModel_bottom'>
                                                    <div className='newsDetail_relatedModel_bottomInner'>
                                                        <p className='fontSize_16 fontColor_lightBlue fontWeight_medium'>
                                                            {_numToPriceBetween(JSON.parse(value?.variant_object)?.min_price, JSON.parse(value?.variant_object)?.max_price)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CustomLink>
                                    )
                                    return result
                                },[])
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export const AddMyVehicleBox = (props) => {
    const { t={t}, data, isSelect = false, selectMoreOnClick = () => {} } = props
    const router = useRouter();

    const _handleOnClick = () => {
        if(data?.link > 0){
            router.push(pageLink.profile.vehicleDetail + data?.link);
        }
    }

    return (
        <div className="addMyVehicleDrawer_body_archiveVehicle_box">
            <div onClick={()=>_handleOnClick()}>
                <div className="addMyVehicleDrawer_body_archiveVehicle_vehicleRow">
                    <div className="addMyVehicleDrawer_body_archiveVehicle_box_image">
                        {
                            !_.isEmpty(data?.image_path) ?
                            <img className="w-100" src={`${process.env.NEXT_PUBLIC_FS_URL + data?.image_path}`} />
                            :
                            <img className="w-100" src="/assets/images/logo.png" />
                        }
                    </div>
                    <div className="addMyVehicleDrawer_body_archiveVehicle_box_textRow">
                        <div className="addMyVehicleDrawer_body_archiveVehicle_box_tagBox mr_50">
                            <p className="addMyVehicleDrawer_body_archiveVehicle_box_tag fontSize_14 fontColor_blue">
                                {isSelect == false ? data?.car_plate : data?.brand}
                            </p>
                        </div>
                        <div className="addMyVehicleDrawer_body_archiveVehicle_box_textBox">
                            <p className="addMyVehicleDrawer_body_archiveVehicle_box_text fontSize_16 fontWeight_semiBold">
                                {data?.name}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="dividingLine" style={{position: 'relative'}}></div>
                <div className="addMyVehicleDrawer_body_archiveVehicle_bottomRow">
                    {
                        isSelect == true ?
                        <>
                            <VehicleSpecBox image={"/assets/images/icon/transmission_icon.png"} label={t('transmission')} input={data?.transmission ?? "-"} />
                            <VehicleSpecBox image={"/assets/images/icon/capacity_icon.png"} label={t("capacity")} input={`${data?.capacity > 0 ? data?.capacity + 'L' : '-' }`} />
                        </>
                        :
                        <>
                            <VehicleSpecBox image={"/assets/images/icon/mileage_icon.svg"} label={'Total Mileage'} input={ data?.mileage ?? "-"} inputStyle={{'lineHeight': '2rem'}} />
                            <VehicleSpecBox image={"/assets/images/icon/tax_calender_icon.svg"} label={'Road Tax'} input={`${data?.road_tax_due_date ? data?.road_tax_due_date : '-' }`} inputStyle={{'lineHeight': '2rem'}} />
                        </>
                    }
                </div>
            </div>
            {
                (isSelect == false && data?.is_default == 1) &&
                <div className="addMyVehicleDrawer_body_archiveVehicle_box_isDefault">
                    <p className="fontSize_12 fontWeight_medium fontColor_white">
                        Default
                    </p>
                </div>
            }
            {
                (isSelect == false && data?.is_default != 1) &&
                <div onClick={()=> {selectMoreOnClick(data)}} className="addMyVehicleDrawer_body_archiveVehicle_box_selectMore">
                    <MoreIcon color={'#000000'} />
                </div>
            }
        </div>
    )
}