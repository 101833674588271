import Link from "next/link";
import { CustomLink } from "../app/customLink";

export const ViewButton = (props) => {
    const { type = 'grey', views } = props
    // type grey, red
    let viewButtonStyle = "viewButton viewButton_grey";
    let viewButtonImage = "/assets/images/view_grey_icon.svg";
    if(type == 'red'){
        viewButtonStyle = "viewButton viewButton_red";
        viewButtonImage = "/assets/images/view_red_icon.svg";
    }
    return (
        <div className={viewButtonStyle}>
            <div className='viewButton_icon'>
                <img className='w-100' src={viewButtonImage} />
            </div>
            <div className='fontSize_12 viewButton_text'>
                {views > 0 ? views.toLocaleString('en-US') : 0}
            </div>
        </div>
    )
}

export const VehicleModelTypeButton = (props) => {
    const { type = 1, t } = props
    if(type == 1){
        return (
            <div className="vehicleModelTypeButton_Box">
                <div className="vehicleModelTypeButton vehicleModelTypeButton_blue">
                    <p className="fontSize_10 fontWeight_semiBold fontColor_white">
                        Upcoming
                    </p>
                </div>
            </div>
        )
    } else if (type == 2){
        return (
            <div className="vehicleModelTypeButton_Box">
                <div className="vehicleModelTypeButton vehicleModelTypeButton_green">
                    <p className="fontSize_10 fontWeight_semiBold fontColor_white">
                        New
                    </p>
                </div>
            </div>
        )
    } else if (type == 3){
        return (
            <div className="vehicleModelTypeButton_Box">
                <div className="vehicleModelTypeButton vehicleModelTypeButton_red">
                    <p className="fontSize_10 fontWeight_semiBold fontColor_white">
                        Hot
                    </p>
                </div>
            </div>
        )
    } else if (type == 4){
        return (
            <div className="vehicleModelTypeButton_Box">
                <div className="vehicleModelTypeButton vehicleModelTypeButton_dark">
                    <p className="fontSize_10 fontWeight_semiBold fontColor_white">
                        Not On Sale
                    </p>
                </div>
            </div>
        )
    } else if (type == 5){
        return (
            <div className="vehicleModelTypeButton_Box">
                <div className="vehicleModelTypeButton vehicleModelTypeButton_yellow">
                    <p className="fontSize_10 fontWeight_semiBold fontColor_white">
                        EV
                    </p>
                </div>
            </div>
        )
    } else if (type == 6){
        return (
            <div className="vehicleModelTypeButton_Box">
                <div className="vehicleModelTypeButton vehicleModelTypeButton_purple">
                    <p className="fontSize_10 fontWeight_semiBold fontColor_white">
                        New EV
                    </p>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export const TagButton = (props) => {
    const { label = "brand", linkUrl = "/", disabledLink = false, extraClass = "" } = props
    if(disabledLink == true){
        return (
            <div className={`tagButton_disabled ${extraClass}`}>
                <p className='fontSize_12 fontWeight_semiBold fontColor_darkBlue'>
                    {label}
                </p>
            </div>
        )
    }
    return (
        <CustomLink href={linkUrl}>
            <div className={`tagButton ${disabledLink == false && "cursorPointer"} ${extraClass}`}>
                <p className='fontSize_12 fontWeight_semiBold fontColor_darkBlue'>
                    {label}
                </p>
            </div>
        </CustomLink>
    )
}

export const PlayButton = (props) => {
    const { size = "md"} = props
    let playButtonCircle = "playButtonCircle playButtonCircle_md"
    let playButtonCircle_icon = "playButtonCircle_icon_md"
    if(size == "sm"){
        playButtonCircle = "playButtonCircle playButtonCircle_sm"
        playButtonCircle_icon = "playButtonCircle_icon_sm"
    }
    return (
        <>
            <div className="playButton">
                <div className={playButtonCircle}>
                    <div className={playButtonCircle_icon}>
                        <img className='w-100' src="/assets/images/play_button_icon.svg" />
                    </div>
                </div>
            </div>
        </>
    )
}

export const FouthButton = (props) =>{
    const { image = undefined, text = "text", handleOnclick = ()=>{}, disabled = false } = props
    return (
        <div onClick={handleOnclick} disabled={disabled} className="fouthButton cursorPointer">
            {image}
            <p className="fontSize_14 fontWeight_bold fontColor_darkBlue2">
                {text}
            </p>
        </div>
    )
}

export const FifthButton = (props) =>{
    const { image = undefined, text = "text", handleOnclick = ()=>{}, disabled = false } = props
    return (
        <div onClick={handleOnclick} disabled={disabled} className="fifthButton cursorPointer">
            {image}
            <p className="fontSize_14 fontColor_lightBlue4">
                {text}
            </p>
        </div>
    )
}