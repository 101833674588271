import { Loader } from "@mantine/core"

export const EmptyData = (props) =>{
    const { t, isLoading = false } = props

    return (
        <div className="emptyData">
            {
                isLoading ?
                <>
                    <Loader color="gray" size="xs" />
                    <p className="fontSize_12 emptyData_color_text">
                        {t('loading')}
                    </p>
                </>
                :
                <p className="fontSize_14 fontWeight_semiBold emptyData_color_text">
                    {t('empty_data')}
                </p>
            }
        </div>
    )
}