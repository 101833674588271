import { isEmpty } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import Site, { navigationWebview } from "../../config/site";

const HandleCustomLink = (props) => {
    const {children = <></>, href = "/", action = "navigation", isLoadedInFlutterApp = false, isStandaloneInFlutterApp = false} = props;
    // action: navigation, pop, media

    const handledOnClick = () => {
        if(!isEmpty(window.flutter_inappwebview)){
            var stringifiedData = JSON.stringify({"action" : action, "path" : href});
            window.flutter_inappwebview.callHandler('handlerName', stringifiedData);
        }
    }
    // const handledOnClick = () => {
    //     if(!isEmpty(Print)){
    //         var stringifiedData = JSON.stringify({"action" : action, "path" : href});
    //         Print.postMessage(stringifiedData);
    //     }
    // }

    if(isLoadedInFlutterApp == true && action == 'navigation' && isStandaloneInFlutterApp == true){
        return <a onClick={() => handledOnClick()} style={{display: "contents"}}>
            {children}
        </a>
    }

    // if(isLoadedInFlutterApp == true && action != null){
    //     return <a onClick={() => handledOnClick()} style={{display: "contents"}}>
    //         {children}
    //     </a>
    // }

    return <Link href={href}>
        {/* <a style={{display: "contents"}}> */}
            {children}
        {/* </a> */}
    </Link>
}

// mapStateToProps function to map Redux state to component props
const mapStateToProps = (state) => {
    return {
        isLoadedInFlutterApp: state?.path?.isLoadedInFlutterApp ?? false, // Assuming 'isLoadedInFlutterApp' is the Redux state you want to access
        isStandaloneInFlutterApp: state?.path?.isStandaloneInFlutterApp ?? false
    }
};

// Connect the component to Redux
export const CustomLink = connect(mapStateToProps)(HandleCustomLink);