import _ from "lodash"
import { Skeleton } from "@mantine/core"
import { VehicleModelTypeButton } from "./button"

export const VehicleImage = (props) => {
    const { imagePath, loading = false, tagType = 0 } = props
    return (
        <div className='vehicleImageOuter'>
            {
                loading == false ?
                <>
                    <img 
                        className="vehicleImage"
                        src={`${!_.isEmpty(imagePath) ? process.env.NEXT_PUBLIC_FS_URL + imagePath : "/assets/images/empty/empty_vehicle.png"}`}
                    />
                    <div className="vehicleImage_tag">
                        <VehicleModelTypeButton type={tagType}/>
                    </div>
                </>
                :
                <div className="vehicleImage" style={{width: '100%', height: '100%'}}>
                    <div className="exmptyVehicleImageMask">
                        <Skeleton height={'100%'} width={'100%'} />
                    </div>
                </div>
            }
            
        </div>
    )
}

export const ProfilePicture = (props) => {
    const { imagePath, loading = false, size = 7.1 } = props
    return(
        <>
            <div className='profile_image' style={{width: `${size}rem`, height: `${size}rem`}}>
                {
                    loading == false ?
                    <>
                        {
                            !_.isEmpty(imagePath) ?
                            <img 
                                className="profile_image_img"
                                src={`${imagePath}`}
                            />
                            :
                            <div className="emptyProfile_imageOutler">
                                <div className={"emptyProfile_image"}>
                                    <img 
                                        className="w-100"
                                        src="/assets/images/keylogo2_active.png"
                                    />
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div className="vehicleImage" style={{width: '100%', height: '100%'}}>
                        <div className="exmptyVehicleImageMask">
                            <Skeleton height={'100%'} width={'100%'} />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export const LogoSecondary = (props) => {
    const { isActive = false } = props
    return (
        <div className={`logoSecondary`}>
            {
                isActive == true ?
                <img className="w-100" src="/assets/images/keylogo2_active.png" />
                :
                <img className="w-100" src="/assets/images/keylogo2_inactive.png" />
            }
        </div>
    )
}

export const TyreImage = (props) => {
    const { imagePath, loading = false, tagType = 0 } = props
    return (
        <div className='vehicleImageOuter'>
            {
                loading == false ?
                <>
                    <img 
                        className="vehicleImage"
                        src={`${!_.isEmpty(imagePath) ? process.env.NEXT_PUBLIC_FS_URL + imagePath : "/assets/images/empty/empty_tyre.png"}`}
                    />
                    <div className="vehicleImage_tag">
                        <VehicleModelTypeButton type={tagType}/>
                    </div>
                </>
                :
                <div className="vehicleImage" style={{width: '100%', height: '100%'}}>
                    <div className="exmptyTyreImageMask">
                        <Skeleton height={'100%'} width={'100%'} />
                    </div>
                </div>
            }
            
        </div>
    )
}