import { isEmpty } from "lodash";

export const customRouterLink = async(props) => {
    try {
        const {router, href, action = 'navigation', target} = props;
        const isStandaloneInFlutterApp = window?.isStandaloneInFlutterApp ?? false;
        const isLoadedInFlutterApp = window?.isLoadedInFlutterApp ?? false;
        if(action == 'navigation'){
            if(isLoadedInFlutterApp == true &&  isStandaloneInFlutterApp == true){
                // if(!isEmpty(Print)){
                //     var stringifiedData = JSON.stringify({"action" : action, "path" : href});
                //     Print.postMessage(stringifiedData);
                //     return
                // }
                if(!isEmpty(window.flutter_inappwebview)){
                    var stringifiedData = JSON.stringify({"action" : action, "path" : href});
                    window.flutter_inappwebview.callHandler('handlerName', stringifiedData);
                }
            } else {
                router.push(href);
            }
        } else if (action == 'navigationTab'){
            if(isLoadedInFlutterApp == true){
                // if(!isEmpty(Print)){
                //     var stringifiedData = JSON.stringify({"action" : action, "path" : href});
                //     Print.postMessage(stringifiedData);
                //     return
                // }
                if(!isEmpty(window.flutter_inappwebview)){
                    var stringifiedData = JSON.stringify({"action" : action, "path" : href});
                    window.flutter_inappwebview.callHandler('handlerName', stringifiedData);
                }
            } else {
                if(target == '_blank'){
                    window.open(href, '_blank');
                } else {
                    window.location.href = href;
                }
            }
        }
    } catch(e) {
        console.log(e)
    }
}

export const customOpenModal = (props) => {
    try{
        const {href, action = "loginModal", setOpenModal = () => {}} = props;
        const isLoadedInFlutterApp = window?.isStandaloneInFlutterApp ?? false;

        if(action == 'loginModal'){
            if(isLoadedInFlutterApp == true){
                // if(!isEmpty(Print)){
                //     var stringifiedData = JSON.stringify({"action" : action, "path" : href});
                //     Print.postMessage(stringifiedData);
                //     return
                // }
                if(!isEmpty(window.flutter_inappwebview)){
                    var stringifiedData = JSON.stringify({"action" : action, "path" : href});
                    window.flutter_inappwebview.callHandler('handlerName', stringifiedData);
                }
            } else {
                setOpenModal(true)
                return 
            }
        }
    } catch(e) {
        console.log(e);
    }
}