import React, { useState, useEffect } from 'react';
import { Modal, TextInput, LoadingOverlay, Skeleton } from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"
import { useForm } from "@mantine/form"
import { toast } from "@/libraries/swal";
import { useRouter } from "next/router";
import Link from 'next/link';
import { _postURL } from "@/api/index";
import _, { isEmpty } from 'lodash';
import { setCookie } from 'cookies-next';
import $ from 'jquery';
import { VehicleBrandList } from '../vehicle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { VehicleImage } from '../common/image';
import { SkeletonParagraph } from '../common/skeleton';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import { cookieExpireMaxAge, countryState, mobileViewWidth, pageLink, placementArea, qrCode } from '../../config/site';
import Redux from "@/action";
import { PlacementBanner } from '@/components/placement';
import { FiLink, FiX } from 'react-icons/fi';
import { Calendar } from '@mantine/dates';
import { CustomLink } from '../app/customLink';
import { customRouterLink } from '../../libraries/appCommon';
import { removeIsWebviewPath } from '../../libraries/common';

export const VideoModal = (props) => {
    const {opened, setOpened, videoLink} = props
    return (
        <Modal 
            opened={opened} 
            onClose={()=>{setOpened(false)}}
            withCloseButton={false}
            centered
            classNames={{
                inner: "modal_videoInner",
                modal: "modal_videoModal"
            }}
        >
            <div className="modal_videoModal_iframeContainer">
                <iframe 
                    className="modal_videoModal_iframe"
                    width="100%" 
                    height="100%"
                    src={videoLink}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen 
                />
            </div>
        </Modal>
    )
}

export const FirstTimeLoginModal = (props) => {
    const { opened, setOpened, t, isWriteReview = false, isWriteTyreReview = false, prevFormData = {}, loginModalSetOpened = () => {} } = props
    const { store } = Redux();
    const router = useRouter();
    const [visible2, setVisible2] = useState(false);
    

    const { getInputProps, onSubmit, isValid, values, setFieldError, reset, setFieldValue, clearFieldError, errors } = useForm({
        validateInputOnChange: true,
        initialValues: {
            firstName: '',
            lastName: '',
            dob: null,
            referralCode: '',
        },
        validate: {
            firstName: (inputValue) => inputValue.length <= 0 && "First name cannot empty",
            lastName: (inputValue) => inputValue.length <= 0 && "Last name cannot empty",
            dob: (inputValue) => inputValue == null && "DOB cannot empty"
        }
    });

    useEffect(()=>{
        if(opened == true){
            if(values?.referralCode?.length == 0){
                if(store?.path?.loginCheck?.firstTime == true && !_.isEmpty(store?.path?.loginCheck?.ref_code)){
                    setFieldValue('referralCode', store?.path?.loginCheck?.ref_code)
                }
            }
        }
    },[opened])

    const _firstLogin = async(formData) => {
        try {
            setVisible2(true);
            let req = {
                "phone_number": prevFormData.phoneNumber,
                "phone_otp": prevFormData.phoneOTP,
                "first_name": formData.firstName,
                "last_name": formData.lastName,
                "date_of_birth": moment(new Date(formData.dob)).format('YYYY-MM-DD'),
                // "referral_code": formData.referralCode
            }
            const response = await _postURL("/api/auth/login", {...req});

            if(!_.isEmpty(response.data)){
                setCookie('user', JSON.stringify(response.data), {maxAge: cookieExpireMaxAge});
                toast.success(t('login_successfully'));
                router.push('/profile')
                // setOpened(false);
                // loginModalSetOpened(false);
                return
            }
            if(isWriteReview == true){
                router.push(router.query.vehicle[0] + "/" + router.query.vehicle[1] + "/write-review")
                return
            }
            if(isWriteTyreReview == true){
                router.push(router.query.tyre[0] + "/" + router.query.tyre[1] + "/write-review")
                return
            }
            // router.push('/profile')
            return
        } catch (error) {
            toast.error(error)
        } finally {
            setVisible2(false);
        }
    }

    return (
        <>
            <Modal
                opened={opened} 
                onClose={()=>{setOpened(false)}}
                withCloseButton={false}
                fullScreen
                classNames={{
                    inner: "modal_loginInner",
                    modal: "modal_loginModal",
                    body: "modal_loginBody",
                }}
            >
                <div className="modal_loginBodyContainer">
                    <div className="modal_loginBody_close">
                        <div 
                            className="modal_loginBody_closeIcon"
                            onClick={async()=>{setOpened(false)}} 
                        >
                            <img className="w-100" src="/assets/images/close.svg"/>
                        </div>
                    </div>
                    <div className="modal_loginBody_section">
                        <img className="w-100" src="/assets/images/login_car.png"/>
                        <div className="modal_loginBody_contantContainer"  style={{paddingBottom: '2.4rem'}}>
                            <LoadingOverlay visible={visible2} overlayBlur={2} />
                            <form onSubmit={onSubmit((values) => _firstLogin(values))} className="modal_loginBody_contantContainerInner">
                                <div>
                                    <div className="modal_loginBody_contantLogo">
                                        <img className="w-100" src="/assets/images/logo.png"/>
                                    </div>
                                    {/* <p className="fontSize_20 fontWeight_extraBold modal_loginBody_contantText">
                                        {t('signup_login')}
                                    </p> */}
                                    <div className="modal_loginBody_contantTextInputMargin">
                                        <TextInput 
                                            placeholder={"First Name"}
                                            classNames={{
                                                input: "fontSize_14 textInput",
                                                error: "fontSize_12 fontColor_red textInputError"
                                            }}
                                            {...getInputProps('firstName')}
                                            autoFocus={false}
                                        />
                                    </div>
                                    <div className="modal_loginBody_contantTextInputMargin">
                                        <TextInput 
                                            placeholder={"Last Name"}
                                            classNames={{
                                                input: "fontSize_14 textInput",
                                                error: "fontSize_12 fontColor_red textInputError"
                                            }}
                                            {...getInputProps('lastName')}
                                            autoFocus={false}
                                        />
                                    </div>
                                    <div className="modal_loginBody_contantTextInputMargin">
                                        <DatePicker 
                                            placeholder={"Date Of Birth"}
                                            format='dd MMM yyyy'
                                            placement="topStart" 
                                            oneTap={true}
                                            onChange={(value)=>{setFieldValue('dob', value)}}
                                        />
                                        {
                                            errors?.dob &&
                                            <p className='textInputError fontSize_12 fontColor_red'>
                                                {errors?.dob}
                                            </p>
                                        }
                                    </div>
                                    {/* <div className="modal_loginBody_contantTextInputMargin">
                                        <TextInput 
                                            placeholder={"Referral Code (Optional)"}
                                            classNames={{
                                                input: "fontSize_14 textInput",
                                                error: "fontSize_12 fontColor_red textInputError"
                                            }}
                                            {...getInputProps('referralCode')}
                                            autoFocus={false}
                                        />
                                    </div> */}
                                </div>
                                <button type="submit" disabled={isValid() ? false : true} className="submitLoginButton primaryButtonBackground">
                                    <p className="fontSize_18 fontWeight_bold fontColor_white">
                                        {t('continue')}
                                    </p>
                                </button>
                            </form>
                        </div>
                        <div className="modal_loginBottom">
                            {/* <p className="fontSize_12 fontColor_grey modal_loginBottom_text">
                                {t('by_clicking_log_in_button_you_agree_to_our')}
                                <Link href={pageLink.general.privacyPolicy}>
                                    <span className="fontSize_12 fontWeight_bold fontColor_redHover modal_loginBottom_textRed cursorPointer">{t('privacy_policy')}</span>
                                </Link>.
                            </p> */}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export const LoginModal = (props) => {
    const { opened, setOpened, t, isWriteReview = false, isWriteTyreReview = false } = props
    const [countdown, setCountdown] = useState(t('request_otp'))
    const router = useRouter();
    const [visible, setVisible] = useState(false);
    const [opened2, setOpened2] = useState(false)
    const [otpDisable, setOtpDisable] = useState(false)

    const { getInputProps, onSubmit, isValid, values, setFieldError, reset, setFieldValue, clearFieldError } = useForm({
        validateInputOnChange: true,
        initialValues: {
            phoneNumber: '',
            phoneOTP: '',
        },
        validate: {
            phoneNumber: (inputValue) => inputValue.length <= 0 
                ? t('phone_number_cannot_be_empty') 
                : !(/^(\+?6?01)[02-46-9]-*[0-9]{7}$|^(\+?6?01)[1]-*[0-9]{8}$/.test(inputValue)) ? t('only_malaysia_phone_number_is_allowed') : null,
            phoneOTP: (inputValue) => inputValue.length !== 6 && t('otp_must_be_6_digits'),
        }
    });

    useEffect(() => {
        if(countdown >= 0){
            const intervalId = setInterval(() => {
                if(countdown != 0){
                    setCountdown(countdown - 1);
                }
                if(countdown == 0){
                    setCountdown(t('resend_otp'));
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [countdown]);

    const _sendOTP = async() => {
        if(values?.phoneNumber){
            let phone_number = values.phoneNumber;
            try {
                let req = {
                    "phone_number": phone_number
                }
                const response = await _postURL("/api/auth/sendOTP", {...req});
                if(!_.isEmpty(response)){
                    setCountdown(60);
                    toast.success(response.message, true);
                }
            } catch (error) {
                toast.error(error)
            }
        }
        else{
            toast.error(t('phone_number_cannot_be_empty'));
        }
    }

    const _checkOTP = async() => {
        // OTP length Check
        if(values?.phoneOTP?.length == 6){
            let phone_number = values.phoneNumber;
            let phone_otp = values.phoneOTP;
            try {
                let req = {
                    "phone_number": phone_number,
                    "phone_otp": phone_otp
                }
                const response = await _postURL("/api/auth/checkOTP", {...req});
                if(!_.isEmpty(response)){
                    if(!response.data){
                        setFieldError('phoneOTP', t('otp_incorrect'));
                    } else {
                        setOtpDisable(true)
                    }
                }
            } catch (error) {
                toast.error(error)
            }
        }
    }

    const _login = async(formData) => {
        try {
            setVisible(true);
            let req = {
                "phone_number": formData.phoneNumber,
                "phone_otp": formData.phoneOTP
            }
            const response = await _postURL("/api/auth/login", {...req});

            if (!_.isObjectLike(response)) throw t("something_unexpected_happen_please_try_again_later")
            if (_.isEmpty(response.data)) throw t("something_unexpected_happen_please_try_again_later")

            if(response?.data?.is_sign_up == true){
                setOpened2(true);
            } else if (!_.isEmpty(response.data?.user_id)) {
                setCookie('user', JSON.stringify(response.data), {maxAge: cookieExpireMaxAge});
                toast.success(t('login_successfully'));
                // setOpened(false);
                router.push('/profile')
                return
            }
            if(isWriteReview == true){
                router.push(router.query.vehicle[0] + "/" + router.query.vehicle[1] + "/write-review")
                return
            }
            if(isWriteTyreReview == true){
                router.push(router.query.tyre[0] + "/" + router.query.tyre[1] + "/write-review")
                return
            }
            // router.push('/profile')
            return
        } catch (error) {
            toast.error(error)
        } finally {
            setVisible(false);
        }
    }

    return (
        <>
            <Modal
                opened={opened} 
                onClose={()=>{setOpened(false)}}
                withCloseButton={false}
                fullScreen
                classNames={{
                    inner: "modal_loginInner",
                    modal: "modal_loginModal",
                    body: "modal_loginBody",
                }}
                // styles={{
                //     body: {width: height >= 1080 ? 'calc(1080px * 0.582)' : 'calc(100vh * 0.582)'}
                // }}
            >
                <div className="modal_loginBodyContainer">
                    <div className="modal_loginBody_close">
                        <div 
                            className="modal_loginBody_closeIcon"
                            onClick={async()=>{
                                setOpened(false); 
                                await setFieldValue('phoneOTP', ''); 
                                await clearFieldError('phoneOTP')}
                            } 
                        >
                            <img className="w-100" src="/assets/images/close.svg"/>
                        </div>
                    </div>
                    <div className="modal_loginBody_section">
                        <img className="w-100" src="/assets/images/login_car.png"/>
                        <div className="modal_loginBody_contantContainer">
                            <LoadingOverlay visible={visible} overlayBlur={2} />
                            <form onSubmit={onSubmit((values) => _login(values))} className="modal_loginBody_contantContainerInner">
                                <div>
                                    <div className="modal_loginBody_contantLogo">
                                        <img className="w-100" src="/assets/images/logo.png"/>
                                    </div>
                                    <p className="fontSize_20 fontWeight_extraBold modal_loginBody_contantText">
                                        {t('signup_login')}
                                    </p>
                                    <div className="modal_loginBody_contantTextInputMargin">
                                        <TextInput 
                                            id='phone_number'
                                            placeholder={t("phone_number_placeholder")}
                                            classNames={{
                                                input: "fontSize_14 textInput",
                                                error: "fontSize_12 fontColor_red textInputError"
                                            }}
                                            type={"number"}
                                            {...getInputProps('phoneNumber')}
                                            autoFocus={false}
                                            
                                        />
                                    </div>
                                    <div className="modal_loginBody_contantTextInputMargin">
                                        <TextInput 
                                            id='otp_code'
                                            disabled={otpDisable}
                                            rightSection={
                                                <button 
                                                    type="button"
                                                    className="fontSize_14 fontWeight_medium fontColor_redHover textInputRequestOPT"
                                                    disabled={countdown >= 0 ? true : false}
                                                    onClick={()=>{_sendOTP()}}
                                                >
                                                    {countdown}
                                                </button>
                                            }
                                            onKeyUp={()=>{_checkOTP()}}
                                            rightSectionWidth={"11.6rem"}
                                            placeholder={t("enter_otp_placeholder")}
                                            classNames={{
                                                input: "fontSize_14 textInput",
                                                error: "fontSize_12 fontColor_red textInputError"
                                            }}
                                            maxLength={6}
                                            {...getInputProps('phoneOTP')}
                                        />
                                    </div>
                                </div>
                                <button type="submit" disabled={isValid() ? false : true} className="submitLoginButton primaryButtonBackground">
                                    <p className="fontSize_18 fontWeight_bold fontColor_white">
                                        {t('continue')}
                                    </p>
                                </button>
                            </form>
                        </div>
                        <div className="modal_loginBottom">
                            <p className="fontSize_12 fontColor_grey modal_loginBottom_text">
                                {t('by_clicking_log_in_button_you_agree_to_our')}
                                <CustomLink href={pageLink.general.privacyPolicy}>
                                    <span className="fontSize_12 fontWeight_bold fontColor_redHover modal_loginBottom_textRed cursorPointer">{t('privacy_policy')}</span>
                                </CustomLink>.
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
            <FirstTimeLoginModal 
                opened={opened2}
                setOpened={setOpened2}
                loginModalSetOpened={setOpened}
                t={t}
                isWriteReview={isWriteReview}
                isWriteTyreReview={isWriteTyreReview}
                prevFormData={values}
            />
        </>
    )
}

export const ImageModal = (props) =>{
    const { setOpened, opened, data = [], currentIndex, setCurrentIndex, thumbsSwiper, setThumbsSwiper, t, isFullLink = false } = props
    const { store } = Redux();
    
    // let dataArray = data if write like this will effect parent data add placement
    let dataArray = [...data]
    
    if(data.length >= 3 && opened == true){
        if(!_.isEmpty(store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.placement_id)){
            if(dataArray?.[3] != 'placement'){
                dataArray.splice(3, 0, 'placement');
            }
        }
    }

    const _updateIndex = (swiperInstance) => {
        if (swiperInstance === null) return;
        const currentSlide = swiperInstance?.activeIndex;
        setCurrentIndex(currentSlide)

        if (thumbsSwiper && !thumbsSwiper.destroyed) {
            thumbsSwiper.slideTo(currentSlide);
        }
    }

    return (
        <Modal 
            opened={opened} 
            onClose={()=>{setOpened(false)}}
            withCloseButton={false}
            centered
            fullScreen
            // size={"xl"}
            classNames={{
                inner: "modal_imageInner",
                modal: "modal_imageModal"
            }}
        >
            <div className="modal_imageBackground">
                <div className='modal_imageHeader containerPadding'>
                    <p className='fontSize_12 fontColor_white'>
                        {currentIndex >= 0 && ((currentIndex + 1) + " / " + dataArray.length)}
                    </p>
                    <div onClick={()=>{setOpened(false)}} className='modal_imageHeader_closeIcon cursorPointer'>
                        <img className='w-100' src="/assets/images/close.svg" />
                    </div>
                </div>
                <div>
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                        onActiveIndexChange={_updateIndex}
                        initialSlide={currentIndex}
                        // loop
                    >
                        {
                            _.reduce(dataArray, (result, value, key)=>{
                                if(value == 'placement'){
                                    result.push(
                                        <SwiperSlide key={key}>
                                            <div className='moda_image_mainImage'>
                                                <div 
                                                    className='moda_image_mainImageBackground' 
                                                    // style={{backgroundImage: `url(${isFullLink ? process.env.NEXT_PUBLIC_FS_URL + value : value})`}} 
                                                >
                                                    <PlacementBanner
                                                        // can't pass object because inside is react memo, pass object wont work the memo
                                                        loading={store?.path?.dataPlacement == undefined ? true : false}
                                                        area={placementArea?.gallerModal_3rdImage} 
                                                        placement_id={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.placement_id}
                                                        type={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.type}
                                                        image_path={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.image_path}
                                                        url={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.url}
                                                        script={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.script}
                                                    />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                } else {
                                    result.push(
                                        <SwiperSlide key={key}>
                                            <div className='moda_image_mainImage'>
                                                <div 
                                                    className='moda_image_mainImageBackground' 
                                                    style={{backgroundImage: `url(${isFullLink ? process.env.NEXT_PUBLIC_FS_URL + value : value})`}} 
                                                />
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                                
                                return result
                            },[])
                        }
                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={'auto'}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                        centeredSlides
                    >
                        {
                            _.reduce(dataArray, (result, value, key)=>{
                                if(value == 'placement'){
                                    result.push(
                                        <SwiperSlide key={key}>
                                            <div className={'moda_image_subImage'}>
                                                <div 
                                                    className={key == currentIndex ? 'moda_image_subImageBackground moda_image_subImageBackground_active' : 'moda_image_subImageBackground'} 
                                                    // style={{backgroundImage: `url(${isFullLink ? process.env.NEXT_PUBLIC_FS_URL + value : value})` }}  
                                                >
                                                    <PlacementBanner
                                                        // can't pass object because inside is react memo, pass object wont work the memo
                                                        loading={store?.path?.dataPlacement == undefined ? true : false}
                                                        disabledClick={true}
                                                        area={placementArea?.gallerModal_3rdImage} 
                                                        placement_id={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.placement_id}
                                                        type={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.type}
                                                        image_path={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.image_path}
                                                        url={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.url}
                                                        script={store?.path?.dataPlacement?.[placementArea?.gallerModal_3rdImage]?.script}
                                                    />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                } else {
                                    result.push(
                                        <SwiperSlide key={key}>
                                            <div className={'moda_image_subImage'}>
                                                <div 
                                                    className={key == currentIndex ? 'moda_image_subImageBackground moda_image_subImageBackground_active' : 'moda_image_subImageBackground'} 
                                                    style={{backgroundImage: `url(${isFullLink ? process.env.NEXT_PUBLIC_FS_URL + value : value})` }}  
                                                />
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                                return result
                            },[])
                        }
                    </Swiper>
                </div>
            </div>
        </Modal>
    )
}

export const VehicleBrandModal = (props) => {
    const { vehicleBrandModalOpen, setVehicleBrandModalOpen, handleClickBrandItem = () =>{}, t, vehicleBrandData = [], isMultipleModal = false, activeList = [] } = props
    const { width } = useViewportSize()

    const _handleIndexItemClick = (letter, contactSections) => {
        const sectionElement = document.querySelector(`.modal_vehicleModal`); // the modal class name 
        if (contactSections.current[letter] && sectionElement) {

            const targetSection = contactSections.current[letter];
            targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' }); // scroll to that letter character title
            // const scrollAmount = -modalHeaderRef?.current?.offsetHeight;
            
            const currentScrollPosition = sectionElement.scrollTop; // get current position
            const targetOffsetTop = targetSection.getBoundingClientRect().top; // between this letter character with scroll top height
            const headerHeight = contactSections?.current?.[Object.keys(contactSections?.current)[0]]?.getBoundingClientRect().top // get 1st letter character between scroll top height
            const scrollAmount = -(currentScrollPosition + headerHeight) // - the header height 
            sectionElement.scrollTo({
                top: currentScrollPosition + targetOffsetTop + scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Modal
            opened={vehicleBrandModalOpen} 
            onClose={()=>{setVehicleBrandModalOpen(false)}}
            withCloseButton={false}
            withinPortal={width <= mobileViewWidth ? true : false}
            fullScreen
            classNames={{
                root: "modal_vehicleRoot",
                modal: "modal_vehicleModal"
            }}
            className="modal_vehicle"
        >
            <div className="modal_vehicleModalStickyHeader">
                <div className='headerBack containerPadding'>
                    <div className='headerBack_row'>
                        <div onClick={()=>{ setVehicleBrandModalOpen(false)  }} className="headerBack_backIconOuter cursorPointer">
                            <div className="headerBack_backIcon">
                                <img className='w-100' src="/assets/images/headerBack_icon.svg" />
                            </div>
                        </div>
                        <div className='header_logo cursorPointer'>
                            <img className='w-100' src="/assets/images/logo.png" />
                        </div>
                        <div className='header_iconList'>
                            {/* <div className="headerBack_closeIcon">
                                <img className='w-100' src="/assets/images/close_black_icon.svg" />
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="modal_vehicle_selectHeader">
                    {
                        isMultipleModal &&
                        <div className="modal_vehicle_selectHeaderInner containerPadding">
                            <p className="fontSize_16 fontWeight_semiBold fontColor_white">
                                {t('select_a_brand')}
                            </p>
                            <p className="fontSize_16 fontWeight_semiBold fontColor_white">
                                (1/3)
                            </p>
                        </div>
                    }
                </div>
            </div>
            <div>
                <VehicleBrandList data={vehicleBrandData} t={t} showSideBar={true} handleIndexItemClick={_handleIndexItemClick} handleClickBrandItem={handleClickBrandItem} activeList={activeList} />
            </div>
        </Modal>
    )
}

export const VehicleModelModal = (props) => {
    const { vehicleModelModalOpen, setVehicleModelModalOpen, handleClickModelItem = () =>{}, t, vehicleModelData = {}, isMultipleModal = false, vehicleBrandImage, loading = false } = props
    const { width } = useViewportSize()

    return (
        <Modal
            opened={vehicleModelModalOpen} 
            onClose={()=>{setVehicleModelModalOpen(false)}}
            withCloseButton={false}
            withinPortal={width <= mobileViewWidth ? true : false}
            fullScreen
            classNames={{
                root: "modal_vehicleRoot",
                modal: "modal_vehicleModal"
            }}
            className="modal_vehicle"
            transition={"slide-left"}
            transitionDuration={200}
            transitionTimingFunction={"linear"}
        >
            <>
                <div className="modal_vehicleModalStickyHeader">
                    <div className='headerBack containerPadding'>
                        <div className='headerBack_row'>
                            <div onClick={()=>{ setVehicleModelModalOpen(false)  }} className="headerBack_backIconOuter cursorPointer">
                                <div className="headerBack_backIcon">
                                    <img className='w-100' src="/assets/images/headerBack_icon.svg" />
                                </div>
                            </div>
                            <div className='header_logo cursorPointer'>
                                <img className='w-100' src="/assets/images/logo.png" />
                            </div>
                            <div className='header_iconList'>
                                {/* <div className="headerBack_closeIcon">
                                    <img className='w-100' src="/assets/images/close_black_icon.svg" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="modal_vehicle_selectHeader">
                        {
                            isMultipleModal &&
                            <div className="modal_vehicle_selectHeaderInner containerPadding">
                                <p className="fontSize_16 fontWeight_semiBold fontColor_white">
                                    {t('select_a_model')}
                                </p>
                                <p className="fontSize_16 fontWeight_semiBold fontColor_white">
                                    (2/3)
                                </p>
                            </div>
                        }
                    </div>
                </div>
                <div className="containerPaddingBottom containerPadding">
                    <div className="modal_vehicleModal_modelBrands_imageOuter">
                        <div className="modal_vehicleModal_modelBrands_image" style={{backgroundImage: `url(${process.env.NEXT_PUBLIC_FS_URL + vehicleBrandImage})`}}  />
                    </div>
                    <div className="modal_vehicleModal_modelList">
                        {
                            loading == true ?
                            <>
                                {
                                    _.times(6, (key)=>(
                                        <div key={key} className="modal_vehicleModal_modelItem">
                                            <div className="modal_vehicleModal_modelItem_imageOuter">
                                                <VehicleImage 
                                                    loading={true}
                                                />
                                            </div>
                                            <div className="fontSize_14 fontWeight_medium modal_vehicleModal_modelItem_text ">
                                                <SkeletonParagraph row={1} isTitle />
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                            :
                            <>
                                {
                                    _.reduce(vehicleModelData, (result, value, key)=>{
                                        result.push(
                                            <div onClick={()=>handleClickModelItem(value)} key={key} className="modal_vehicleModal_modelItem cursorPointer">
                                                <div className="modal_vehicleModal_modelItem_imageOuter">
                                                    <VehicleImage 
                                                        imagePath={value.image_path}
                                                    />
                                                </div>
                                                <p className="fontSize_14 fontWeight_medium modal_vehicleModal_modelItem_text ">
                                                    {value.name}
                                                </p>
                                            </div>
                                        )
                                        return result
                                    },[])
                                }
                            </>
                        }
                    </div>
                </div>
            </>
        </Modal>
    )
}

export const VehicleVariantModal = (props) => {
    const { vehicleVariantModalOpen, setVehicleVariantModalOpen, handleClickVariantItem = () =>{}, t, vehicleVariantData = {}, isMultipleModal = false, vehicleModelActive, isActiveVaraintList = [], loading = false } = props
    const { width } = useViewportSize()

    return (
        <Modal
            opened={vehicleVariantModalOpen} 
            onClose={()=>{setVehicleVariantModalOpen(false)}}
            withCloseButton={false}
            withinPortal={width <= mobileViewWidth ? true : false}
            fullScreen
            classNames={{
                root: "modal_vehicleRoot",
                modal: "modal_vehicleModal"
            }}
            className="modal_vehicle"
            transition={"slide-left"}
            transitionDuration={200}
            transitionTimingFunction={"linear"}
        >
            <>
                <div className="modal_vehicleModalStickyHeader">
                    <div className='headerBack containerPadding'>
                        <div className='headerBack_row'>
                            <div onClick={()=>{ setVehicleVariantModalOpen(false)  }} className="headerBack_backIconOuter cursorPointer">
                                <div className="headerBack_backIcon">
                                    <img className='w-100' src="/assets/images/headerBack_icon.svg" />
                                </div>
                            </div>
                            <div className='header_logo cursorPointer'>
                                <img className='w-100' src="/assets/images/logo.png" />
                            </div>
                            <div className='header_iconList'>
                                {/* <div className="headerBack_closeIcon">
                                    <img className='w-100' src="/assets/images/close_black_icon.svg" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="modal_vehicle_selectHeader">
                        {
                            isMultipleModal &&
                            <div className="modal_vehicle_selectHeaderInner containerPadding">
                                <p className="fontSize_16 fontWeight_semiBold fontColor_white">
                                    {t('select_a_variant')}
                                </p>
                                <p className="fontSize_16 fontWeight_semiBold fontColor_white">
                                    (3/3)
                                </p>
                            </div>
                        }
                    </div>
                </div>
                <div className="modal_vehicleModal_variant">
                    <div className="model_vehicleModal_variantTop containerPadding ">
                        <div className="modal_vehicleModal_variantTop_imageOuter">
                            <VehicleImage 
                                imagePath={vehicleModelActive?.image_path}
                            />
                        </div>
                        <div className="model_vehicleModal_variantTitle">
                            <p className="fontSize_14 fontColor_lightBlue">
                                {vehicleModelActive?.brand_name}
                            </p>
                            <p className="fontSize_16 fontWeight_semiBold fontColor_blue">
                                {vehicleModelActive?.model_name}
                            </p>
                        </div>
                    </div>
                    <div className="model_vehicleModal_variantBottom">
                        <div className={"vehicle_brandlist"}>
                            {
                                loading == true ?
                                <>
                                    <div className='vehiclePage_vehicle_brandlistMargin'>
                                        <div className="vehicle_brandlistContainer">
                                            <div className={"vehicle_brandlist"}>
                                                {
                                                    _.times(3, (key)=>(
                                                        <div key={key}>
                                                            <div className='vehicle_brandlist_titlebox containerPadding'>
                                                                <div style={{width: '15rem'}}>
                                                                    <SkeletonParagraph row={1} isTitle={true} />
                                                                </div>
                                                            </div>
                                                            {
                                                                _.times(3, (key2)=>(
                                                                    <div key={key2} className='containerPadding'>
                                                                        <div className='vehicle_brandlist_rowBox'>
                                                                            <Skeleton height={'4rem'} width={'4rem'} circle />
                                                                            <div style={{width: '20rem', maxWidth: '100%'}}>
                                                                                <SkeletonParagraph row={1}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        _.reduce(_.chain(vehicleVariantData)
                                        .groupBy(variant => variant.launched_year)
                                        .toPairs()
                                        .orderBy(['0'], ['desc'])
                                        .map(pair => ({
                                            key: pair[0],
                                            values: _.orderBy(pair[1], ['sequence'], ['asc']) 
                                        }))
                                        .value(), (result, value)=>{
                                            result.push(
                                                <div key={value.key}>
                                                    <div className='vehicle_brandlist_titlebox containerPadding'>
                                                        <p className='fontSize_16 fontWeight_medium'>
                                                            {value.key}
                                                        </p>
                                                    </div>
                                                    {
                                                        _.reduce(value.values, (result2, value2, key2)=>{
                                                            if(_.includes(isActiveVaraintList, value2.variant_id)){
                                                                result2.push(
                                                                    <div key={`${value.key}-${key2}`} disabled={true} className='containerPadding'>
                                                                        <div className='vehicle_brandlist_rowBox'>
                                                                            <p className='fontSize_14 fontColor_red'>
                                                                                {value2.name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            } else {
                                                                result2.push(
                                                                    <div key={`${value.key}-${key2}`} onClick={()=>{handleClickVariantItem(value2)}} className='containerPadding cursorPointer'>
                                                                        <div className='vehicle_brandlist_rowBox'>
                                                                            <p className='fontSize_14'>
                                                                                {value2.name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            return result2
                                                        },[])
                                                    }
                                                </div>
                                            )
                                            return result
                                        },[])
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    )
}

export const CompareModal = (props) => {
    const { compareModalOpen, setCompareModalOpen, data = [], handleOnClickCancel = () => {}, handleOnClickSubmit = () => {}, bodyContainerHeight = undefined, t } = props
    return (
        <Modal
            opened={compareModalOpen} 
            onClose={()=>{setCompareModalOpen(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "modal_vehicleRoot",
                modal: "modal_vehicleModal"
            }}
            className="modal_vehicle"
        >
            <>
                <div className="modal_vehicleModalStickyHeader">
                    <div className='headerBack containerPadding'>
                        <div className='headerBack_row'>
                            <div onClick={()=>{ setCompareModalOpen(false)  }} className="headerBack_backIconOuter cursorPointer">
                                <div className="headerBack_closeIcon">
                                    <img className='w-100' src="/assets/images/close_black_icon.svg" />
                                </div>
                            </div>
                            <div className='header_logo cursorPointer'>
                                <img className='w-100' src="/assets/images/logo.png" />
                            </div>
                            <div className='header_iconList'>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal_vehicleModal_variant">
                    <div className='compareModal containerPadding' style={{height: bodyContainerHeight ? `calc(${bodyContainerHeight}px - 74px)` : 'unset'}}>
                        <div>
                        {
                            _.reduce(data, (result, value, key)=>{
                                result.push(
                                    <div key={key} className='compareModal_row'>
                                        <div className='compareModal_rowLeft'>
                                            <div className='compareModal_row_image'>
                                                <VehicleImage 
                                                    imagePath={value.model_image_path}
                                                />
                                            </div>
                                            <p className='fontSize_14'>
                                                {value.name}
                                            </p>
                                        </div>
                                        <div className='compareModal_row_closeIcon' onClick={()=>handleOnClickCancel(value.variant_id)}>
                                            <img className='w-100' src="/assets/images/close_black_icon.svg" />
                                        </div>
                                    </div>
                                )
                                return result
                            },[])
                        }
                        </div>
                        <div onClick={()=>handleOnClickSubmit()} className='compareModel_button primaryButtonBackground'>
                            <p className='fontSize_16 fontWeight_bold fontColor_white'>
                                {t('compare')}
                            </p>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    )
}

export const CountryState = (props) => {
    const { countryStateOpen, setCountryStateOpen, handleClickCountryStateItem = () => {} } = props
    const { width } = useViewportSize()
    return (
        <Modal
            opened={countryStateOpen} 
            onClose={()=>{setCountryStateOpen(false)}}
            withCloseButton={false}
            withinPortal={width <= mobileViewWidth ? true : false}
            fullScreen
            classNames={{
                root: "modal_vehicleRoot",
                modal: "modal_vehicleModal"
            }}
            className="modal_vehicle"
        >
            <>
                <div className="modal_vehicleModalStickyHeader">
                    <div className='headerBack containerPadding'>
                        <div className='headerBack_row'>
                            <div onClick={()=>{ setCountryStateOpen(false)  }} className="headerBack_backIconOuter cursorPointer">
                                <div className="headerBack_backIcon">
                                    <img className='w-100' src="/assets/images/headerBack_icon.svg" />
                                </div>
                            </div>
                            <div className='header_logo cursorPointer'>
                                <img className='w-100' src="/assets/images/logo.png" />
                            </div>
                            <div className='header_iconList'>
                                {/* <div className="headerBack_closeIcon">
                                    <img className='w-100' src="/assets/images/close_black_icon.svg" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="modal_vehicle_selectHeader" />
                </div>
                <div className="modal_vehicleModal_variant">
                    <div className={"vehicle_brandlist"}>
                        {
                            _.reduce(countryState, (result, value, key)=>{
                                result.push(
                                    <div key={`${key}`} onClick={()=>{handleClickCountryStateItem(value.value)}} className='containerPadding'>
                                        <div className='vehicle_brandlist_rowBox'>
                                            <p className='fontSize_14'>
                                                {value.label}
                                            </p>
                                        </div>
                                    </div>
                                )
                                return result
                            },[])
                        }
                        
                    </div>
                </div>
            </>
        </Modal>
    )
}

export const PhoneCallListModal = (props) => {
    const { phoneCallOpen, setPhoneCallOpen, phoneNumberList = [], t } = props
    const { width } = useViewportSize()

    if(width <= mobileViewWidth){
        return (
            <Modal
                opened={phoneCallOpen} 
                onClose={()=>{setPhoneCallOpen(false)}}
                withCloseButton={false}
                withinPortal={true}
                centered
                classNames={{
                    root: "modal_vehicleRoot",
                    modal: "modal_vehicleModal"
                }}
                className="modal_vehicle"
            >
                <>
                    <div className="center_modal">
                        {
                            _.reduce(phoneNumberList, (result, value, key)=>{
                                result.push(
                                    <div key={key}>
                                        <div className='phoneCallListModel_title containerPadding'>
                                            <p className='fontSize_16 fontWeight_medium'>
                                                {t(key)}
                                            </p>
                                        </div>
                                        {
                                            _.reduce(value, (result2, value2, key2)=>{
                                                result2.push(
                                                    <a key={`${key}-${key2}`} 
                                                        onClick={()=>{customRouterLink({href: `tel: ${value2}`, action: 'navigationTab'})}}
                                                        className='phoneCallListModel_href containerPadding'
                                                    >
                                                        <div className='phoneCallListModel_href_box'>
                                                            <p className='fontSize_14'>
                                                                {value2}
                                                            </p>
                                                        </div>
                                                    </a>
                                                )
                                                return result2
                                            },[])
                                        }
                                    </div>
                                )
                                return result
                            },[])
                        }
                    </div>
                </>
            </Modal>
        )
    }
    return (
        <Modal
            opened={phoneCallOpen} 
            onClose={()=>{setPhoneCallOpen(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "center_modal_mantine_root",
                modal: "center_modal_mantine_modal",
                body: "center_modal_mantine_body",
                inner: "center_modal_mantine_inner"
            }}
            className="modal_vehicle"
        >
            <div className='center_modal_container'>
                <div onClick={()=>{setPhoneCallOpen(false)}} className='center_modal_overlay'/>
                <div className="center_modal">
                    {
                        _.reduce(phoneNumberList, (result, value, key)=>{
                            result.push(
                                <div key={key}>
                                    <div className='phoneCallListModel_title containerPadding'>
                                        <p className='fontSize_16 fontWeight_medium'>
                                            {t(key)}
                                        </p>
                                    </div>
                                    {
                                        _.reduce(value, (result2, value2, key2)=>{
                                            result2.push(
                                                <a key={`${key}-${key2}`} 
                                                    onClick={()=>{customRouterLink({href: `tel: ${value2}`, action: 'navigationTab'})}}
                                                    // href={`tel: ${value2}`} 
                                                    className='phoneCallListModel_href containerPadding'
                                                >
                                                    <div className='phoneCallListModel_href_box'>
                                                        <p className='fontSize_14'>
                                                            {value2}
                                                        </p>
                                                    </div>
                                                </a>
                                            )
                                            return result2
                                        },[])
                                    }
                                </div>
                            )
                            return result
                        },[])
                    }
                </div>
            </div>
        </Modal>
    )
}

// base modal
export const CenterModal = (props) => {
    const { openModal, setOpenModal, children = <></> } = props
    const { width } = useViewportSize()

    if(width <= mobileViewWidth){
        return (
            <Modal
                opened={openModal} 
                onClose={()=>{setOpenModal(false)}}
                withCloseButton={false}
                withinPortal={true}
                centered
                classNames={{
                    root: "modal_vehicleRoot",
                    modal: "modal_vehicleModal"
                }}
                className="modal_vehicle"
            >
                <div className="center_modal">
                    {children}
                </div>
            </Modal>
        )
    }
    return (
        <Modal
            opened={openModal} 
            onClose={()=>{setOpenModal(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "center_modal_mantine_root",
                modal: "center_modal_mantine_modal",
                body: "center_modal_mantine_body",
                inner: "center_modal_mantine_inner"
            }}
            className="modal_vehicle"
        >
            <div className='center_modal_container'>
                <div onClick={()=>{setOpenModal(false)}} className='center_modal_overlay'/>
                <div className="center_modal">
                    <div className="center_modal">
                        {children}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export const PlacementModal = (props) => {
    const { placementModalOpen = false, setPlacementModalOpen = ()=>{}, children = <></> } = props
    const { width } = useViewportSize()
    return (
        <Modal
            opened={placementModalOpen} 
            onClose={()=>{setPlacementModalOpen(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "center_modal_mantine_root",
                modal: "center_modal_mantine_modal",
                body: "center_modal_mantine_body",
                inner: "center_modal_mantine_inner"
            }}
            className="modal_vehicle"
        >
            <div className='center_modal_container'>
                <div onClick={()=>{setPlacementModalOpen(false)}} className='center_modal_overlay'/>
                <div className='center_modal_closeButtonPosition'>
                    <div className='center_modal_closeButton cursorPointer' onClick={()=>{setPlacementModalOpen(false)}}>
                        <img className='w-100' src="/assets/images/close.svg" />
                    </div>
                </div>
                <div className="placement_modal">
                    <div className="placement_modal">
                        {children}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export const DateModal = (props) => {
    const { openModal = false, setOpenModal = () => {}, handleClickDateChange } = props
    const { width } = useViewportSize();

    const [selectedDate, setSelectedDate] = useState(new Date());

    const _handleClickSubmitDate = () => {
        handleClickDateChange(selectedDate)
        setOpenModal(false)
    }

    const sameUI = (
        <div className="center_modal" style={{maxHeight: 'unset', overflow: 'hidden', display: 'flex', justifyContent: 'center', borderRadius: '2rem', width: 'fit-content'}}>
            <div className='calendar_modal'>
                <Calendar value={selectedDate} onChange={setSelectedDate} />;
                <div className='calendar_modal_buttonRow'>
                    <button onClick={()=>{setOpenModal(false)}} type='button' className='disabledButton'>
                        <p className='fontSize_16 fontWeight_semiBold fontColor_grey' >
                            Cancel
                        </p>
                    </button>
                    <button onClick={_handleClickSubmitDate} type='button' className='secondaryButton'>
                        <p className='fontSize_16 fontWeight_semiBold fontColor_darkBlue2'>
                            Submit
                        </p>
                    </button>
                </div>
            </div>
        </div>
    )
    if(width <= mobileViewWidth){
        <Modal
            opened={openModal}  
            onClose={()=>{setOpenModal(false)}}
            withCloseButton={false}
            withinPortal={true}
            centered
            classNames={{
                root: "modal_vehicleRoot",
                modal: "modal_vehicleModal"
            }}
            className="modal_vehicle"
        >
            {sameUI}
        </Modal>
    }
    return (
        <Modal
            opened={openModal} 
            onClose={()=>{setOpenModal(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "center_modal_mantine_root",
                modal: "center_modal_mantine_modal",
                body: "center_modal_mantine_body",
                inner: "center_modal_mantine_inner"
            }}
            className="modal_vehicle"
        >
            <div className='center_modal_container'>
                <div onClick={()=>{setOpenModal(false)}} className='center_modal_overlay'/>
                    {sameUI}
            </div>
        </Modal>
    )
}

export const ShareGoogleMapModal = (props) => {
    const { shareGoogleMapOpen, setShareGoogleMapOpen, data = [], t } = props
    const { width } = useViewportSize()
    const router = useRouter();

    const copyText = (entryText) => {
        const isLoadedInFlutterApp = window?.isLoadedInFlutterApp ?? false;
        if(isLoadedInFlutterApp == true){
            if(!isEmpty(window.flutter_inappwebview)){
                var stringifiedData = JSON.stringify({"action" : "copyClipboard", "path" : removeIsWebviewPath(entryText)});
                window.flutter_inappwebview.callHandler('handlerName', stringifiedData);
                toast.success('Succes Copy Link');
            }
        } else {
            navigator?.clipboard?.writeText(entryText);
            toast.success('Succes Copy Link');
        }
    }

    if(width <= mobileViewWidth){
        return (
            <Modal
                opened={shareGoogleMapOpen} 
                onClose={()=>{setShareGoogleMapOpen(false)}}
                withCloseButton={false}
                withinPortal={true}
                centered
                classNames={{
                    root: "modal_vehicleRoot",
                    modal: "modal_vehicleModal"
                }}
                className="modal_vehicle"
            >
                <>
                    <div className="center_modal modal_share">
                        <div className='modal_share_header'>
                            <p className='fontSize_18 fontWeight_semiBold'>
                                Share
                            </p>
                            <div onClick={()=>{setShareGoogleMapOpen(false)}}>
                                <FiX color={"#000000"} style={{fontSize: "2rem"}} />
                            </div>
                        </div>
                        <div className='modal_share_linePadding'>
                            <div className='dividingLine' />
                        </div>
                        <div className='modal_share_body'>
                            <p className='fontSize_14'>
                                Share this link via
                            </p>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://facebook.com/sharer/sharer.php?u=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: "_blank"})}}
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/facebook_icon.svg" />
                                </div>
                                <p className='fontSize_16'>
                                    Facebook
                                </p>
                            </a>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://api.whatsapp.com/send?text=${data?.address}%20${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: "_blank"})}}
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/whatsup_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Whatsapp
                                </p>
                            </a>
                            <div className='dividingLine' />
                            <div>
                                <p className='fontSize_14'>
                                    Or copy link
                                </p>
                                <div className='modal_share_body_copyRow'>
                                    <div className='modal_Share_body_copyRow_coprIcon'>
                                        <FiLink color={"#000000"} style={{fontSize: "2rem"}} />
                                    </div>
                                    <div className='modal_Share_body_copyRow_textBox'>
                                        <p className='fontSize_16 modal_Share_body_copyRow_textBox_text' >
                                            {process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}
                                        </p>
                                    </div>
                                    <div onClick={()=>copyText(process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath))} className='modal_Share_body_copyRow_copyButton cursorPointer'>
                                        <p className='fontSize_14 fontColor_white'>
                                            Copy
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        )
    }
    return (
        <Modal
            opened={shareGoogleMapOpen} 
            onClose={()=>{setShareGoogleMapOpen(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "center_modal_mantine_root",
                modal: "center_modal_mantine_modal",
                body: "center_modal_mantine_body",
                inner: "center_modal_mantine_inner"
            }}
            className="modal_vehicle"
        >
            <div className='center_modal_container'>
                <div onClick={()=>{setShareGoogleMapOpen(false)}} className='center_modal_overlay'/>
                <>
                    <div className="center_modal modal_share">
                        <div className='modal_share_header'>
                            <p className='fontSize_18 fontWeight_semiBold'>
                                Share
                            </p>
                            <div onClick={()=>{setShareGoogleMapOpen(false)}}>
                                <FiX color={"#000000"} style={{fontSize: "2rem"}} />
                            </div>
                        </div>
                        <div className='modal_share_linePadding'>
                            <div className='dividingLine' />
                        </div>
                        <div className='modal_share_body'>
                            <p className='fontSize_14'>
                                Share this link via
                            </p>
                            <a 
                                href={`https://facebook.com/sharer/sharer.php?u=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`} target="_blank" rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/facebook_icon.svg" />
                                </div>
                                <p className='fontSize_16'>
                                    Facebook
                                </p>
                            </a>
                            <a 
                                href={`https://api.whatsapp.com/send?text=${data?.address}%20${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`} target="_blank" rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/whatsup_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Whatsapp
                                </p>
                            </a>
                            <div className='dividingLine' />
                            <div>
                                <p className='fontSize_14'>
                                    Or copy link
                                </p>
                                <div className='modal_share_body_copyRow'>
                                    <div className='modal_Share_body_copyRow_coprIcon'>
                                        <FiLink color={"#000000"} style={{fontSize: "2rem"}} />
                                    </div>
                                    <div className='modal_Share_body_copyRow_textBox'>
                                        <p className='fontSize_16 modal_Share_body_copyRow_textBox_text' >
                                            {process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}
                                        </p>
                                    </div>
                                    <div onClick={()=>copyText(process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath))} className='modal_Share_body_copyRow_copyButton cursorPointer'>
                                        <p className='fontSize_14 fontColor_white'>
                                            Copy
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </Modal>
    )
}

export const ShareCommentModal = (props) => {
    const { shareCommentOpen, setShareCommentOpen, data = [], t } = props
    const { width } = useViewportSize()
    const router = useRouter();

    const copyText = (entryText) => {
        const isLoadedInFlutterApp = window?.isLoadedInFlutterApp ?? false;
        if(isLoadedInFlutterApp == true){
            if(!isEmpty(window.flutter_inappwebview)){
                var stringifiedData = JSON.stringify({"action" : "copyClipboard", "path" : removeIsWebviewPath(entryText)});
                window.flutter_inappwebview.callHandler('handlerName', stringifiedData);
                toast.success('Succes Copy Link');
            }
        } else {
            navigator?.clipboard?.writeText(entryText);
            toast.success('Succes Copy Link');
        }
    }

    if(width <= mobileViewWidth){
        return (
            <Modal
                opened={shareCommentOpen} 
                onClose={()=>{setShareCommentOpen(false)}}
                withCloseButton={false}
                withinPortal={true}
                centered
                classNames={{
                    root: "modal_vehicleRoot",
                    modal: "modal_vehicleModal"
                }}
                className="modal_vehicle"
            >
                <>
                    <div className="center_modal modal_share">
                        <div className='modal_share_header'>
                            <p className='fontSize_18 fontWeight_semiBold'>
                                Share
                            </p>
                            <div onClick={()=>{setShareCommentOpen(false)}}>
                                <FiX color={"#000000"} style={{fontSize: "2rem"}} />
                            </div>
                        </div>
                        <div className='modal_share_linePadding'>
                            <div className='dividingLine' />
                        </div>
                        <div className='modal_share_body'>
                            <p className='fontSize_14'>
                                Share this link via
                            </p>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://facebook.com/sharer/sharer.php?u=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/facebook_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Facebook
                                </p>
                            </a>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://twitter.com/intent/tweet?url=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/x_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    X
                                </p>
                            </a>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://telegram.me/share/url?url=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/telegram_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Telegram
                                </p>
                            </a>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://api.whatsapp.com/send?text=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/whatsapp_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Whatsapp
                                </p>
                            </a>
                            <div className='dividingLine' />
                            <div>
                                <p className='fontSize_14'>
                                    Or copy link
                                </p>
                                <div className='modal_share_body_copyRow'>
                                    <div className='modal_Share_body_copyRow_coprIcon'>
                                        <FiLink color={"#000000"} style={{fontSize: "2rem"}} />
                                    </div>
                                    <div className='modal_Share_body_copyRow_textBox'>
                                        <p className='fontSize_16 modal_Share_body_copyRow_textBox_text' >
                                            {process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}
                                        </p>
                                    </div>
                                    <div onClick={()=>copyText(process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath))} className='modal_Share_body_copyRow_copyButton cursorPointer'>
                                        <p className='fontSize_14 fontColor_white'>
                                            Copy
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        )
    }
    return (
        <Modal
            opened={shareCommentOpen} 
            onClose={()=>{setShareCommentOpen(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "center_modal_mantine_root",
                modal: "center_modal_mantine_modal",
                body: "center_modal_mantine_body",
                inner: "center_modal_mantine_inner"
            }}
            className="modal_vehicle"
        >
            <div className='center_modal_container'>
                <div onClick={()=>{setShareCommentOpen(false)}} className='center_modal_overlay'/>
                <>
                    <div className="center_modal modal_share">
                        <div className='modal_share_header'>
                            <p className='fontSize_18 fontWeight_semiBold'>
                                Share
                            </p>
                            <div onClick={()=>{setShareCommentOpen(false)}}>
                                <FiX color={"#000000"} style={{fontSize: "2rem"}} />
                            </div>
                        </div>
                        <div className='modal_share_linePadding'>
                            <div className='dividingLine' />
                        </div>
                        <div className='modal_share_body'>
                            <p className='fontSize_14'>
                                Share this link via
                            </p>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://facebook.com/sharer/sharer.php?u=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/facebook_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Facebook
                                </p>
                            </a>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://twitter.com/intent/tweet?url=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/x_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    X
                                </p>
                            </a>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://telegram.me/share/url?url=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/telegram_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Telegram
                                </p>
                            </a>
                            <a 
                                onClick={()=>{customRouterLink({href: `https://api.whatsapp.com/send?text=${process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}`, action: 'navigationTab', target: '_blank'})}}
                                rel="noopener noreferrer"
                                className='modal_share_body_row'
                            >
                                <div className='media_icon'>
                                    <img className='w-100' src="/assets/images/share_icon/whatsapp_icon.png" />
                                </div>
                                <p className='fontSize_16'>
                                    Whatsapp
                                </p>
                            </a>
                            <div className='dividingLine' />
                            <div>
                                <p className='fontSize_14'>
                                    Or copy link
                                </p>
                                <div className='modal_share_body_copyRow'>
                                    <div className='modal_Share_body_copyRow_coprIcon'>
                                        <FiLink color={"#000000"} style={{fontSize: "2rem"}} />
                                    </div>
                                    <div className='modal_Share_body_copyRow_textBox'>
                                        <p className='fontSize_16 modal_Share_body_copyRow_textBox_text' >
                                            {process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath)}
                                        </p>
                                    </div>
                                    <div onClick={()=>copyText(process.env.NEXT_PUBLIC_FRONTEND_URL + removeIsWebviewPath(router.asPath))} className='modal_Share_body_copyRow_copyButton cursorPointer'>
                                        <p className='fontSize_14 fontColor_white'>
                                            Copy
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </Modal>
    )
}

export const QrModal = (props) => {
    const { openModal = false, setOpenModal, value } = props
    const { width } = useViewportSize()
    const router = useRouter();


    const sameUI = (
        <div className="center_modal modal_share">
            <div className='modal_share_header'>
                <p className='fontSize_18 fontWeight_semiBold'>
                    Qr Code
                </p>
                <div onClick={()=>{setOpenModal(false)}}>
                    <FiX color={"#000000"} style={{fontSize: "2rem"}} />
                </div>
            </div>
            <div className='modal_share_linePadding'>
                <div className='dividingLine' />
            </div>
            <div className='modal_share_body'>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width:'100%'}}>
                    <img src={`${qrCode}${value}`} />
                </div>
                
                {/* <div className='dividingLine' />
                <div>
                    <p className='fontSize_14'>
                        Or copy link
                    </p>
                    <div className='modal_share_body_copyRow'>
                        <div className='modal_Share_body_copyRow_coprIcon'>
                            <FiLink color={"#000000"} style={{fontSize: "2rem"}} />
                        </div>
                        <div className='modal_Share_body_copyRow_textBox'>
                            <p className='fontSize_16 modal_Share_body_copyRow_textBox_text' >
                                {value}
                            </p>
                        </div>
                        <div onClick={()=>copyText(value)} className='modal_Share_body_copyRow_copyButton cursorPointer'>
                            <p className='fontSize_14 fontColor_white'>
                                Copy
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
    if(width <= mobileViewWidth){
        <Modal
            opened={openModal}  
            onClose={()=>{setOpenModal(false)}}
            withCloseButton={false}
            withinPortal={true}
            centered
            classNames={{
                root: "modal_vehicleRoot",
                modal: "modal_vehicleModal"
            }}
            className="modal_vehicle"
        >
            {sameUI}
        </Modal>
    }
    return (
        <Modal
            opened={openModal} 
            onClose={()=>{setOpenModal(false)}}
            withCloseButton={false}
            withinPortal={false}
            fullScreen
            classNames={{
                root: "center_modal_mantine_root",
                modal: "center_modal_mantine_modal",
                body: "center_modal_mantine_body",
                inner: "center_modal_mantine_inner"
            }}
            className="modal_vehicle"
        >
            <div className='center_modal_container'>
                <div onClick={()=>{setOpenModal(false)}} className='center_modal_overlay'/>
                    {sameUI}
            </div>
        </Modal>
    )
}