import { Skeleton } from "@mantine/core"
import _ from "lodash"
import { SkeletonParagraph } from "../common/skeleton"
import Link from "next/link"
import { colorBlueActiveCode, pageLink } from "../../config/site"
import { TagButton } from "../common/button"
import { _handleOnClickDealerCall } from "../../libraries/common"
import { EmptyData } from "../common/emptyData"
import { LoadMore } from '../common/loader';
import { ExpendMore } from "../common/icon"
import { CustomLink } from "../app/customLink"

export const DealerSection = (props) => {
    const { t, data = [], dealerListTotalRow = undefined, brandName, brandCustomUrl = "", loading = false, setPhoneNumberList = () => {}, setPhoneCallOpen = () => {}, isViewMoreButton = true, isListing = false, handleOnClickViewMoreDealer = () => {}, loading2 = false } = props

    if(loading == true){
        return (
            <div className="dealer containerPadding">
                {
                    !_.isEmpty(brandName) &&
                    <div className="dealer_title">
                        <p className="fontSize_16 fontWeight_bold">
                            {`${brandName} ${t('dealer')}`}
                        </p>
                    </div>
                }
                <div className="dealer_content">
                    {
                        _.times(3, (key)=>(
                            <div key={key} className="dealer_item">
                                <div className="dealer_item_row_1">
                                    <div className="fontSize_14 fontWeight_semiBold dealer_item_row_1_title">
                                        <SkeletonParagraph row={1} isTitle />
                                    </div>
                                    <div className="dealer_item_row_1_image">
                                        <img 
                                            className="w-100"
                                            src="/assets/images/right_circle_blue_icon.svg"
                                        />
                                    </div>
                                </div>
                                <div className="dealer_item_row_2">
                                    <div className="dealer_item_row_2_icon">
                                        <img 
                                            className="w-100"
                                            src="/assets/images/address_circle_icon_blue.svg"
                                        />
                                    </div>
                                    <div className="fontSize_12 fontColor_lightGrey dealer_item_row_2_text">
                                        <SkeletonParagraph row={2} />
                                    </div>
                                </div>
                                <div className="dealer_item_row_3">
                                    <div className="secondaryButton positionRelative">
                                        <div className="absoluteSkeletonBox">
                                            <Skeleton height={'100%'} radius={'10rem'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    let dealerLimit = 1
    

    return (
        <>
            {
                !_.isEmpty(data) ?
                <div className="dealer containerPadding">
                    {
                        !_.isEmpty(brandName) &&
                        <div className="dealer_title">
                            <p className="fontSize_16 fontWeight_bold">
                                {`${brandName} ${t('dealer')}`}
                                {/* {`${t('nearest')} ${brandName} ${t('dealer')}`} */}
                            </p>
                        </div>
                    }
                    <div className="dealer_content">
                        {
                            _.reduce(data, (result, value, key)=>{
                                if(dealerLimit <= 5 || isViewMoreButton == false){
                                    dealerLimit++
                                    result.push(
                                        <div key={key} className="dealer_item">
                                            <CustomLink href={pageLink?.dealer?.dealerMidUrl + value.link}>
                                                <div className="cursorPointer">
                                                    <div className="dealer_item_row_1">
                                                        <p className="fontSize_14 fontWeight_semiBold dealer_item_row_1_title">
                                                            {
                                                                !_.isEmpty(value?.rating) &&
                                                                <span className="dealerTitle_row_tag primaryButtonBackground fontSize_10 fontWeight_medium fontColor_white">
                                                                    {value?.rating}
                                                                </span>
                                                            }
                                                            {value.name}
                                                        </p>
                                                        <div className="dealer_item_row_1_image">
                                                            <img 
                                                                className="w-100"
                                                                src="/assets/images/right_circle_blue_icon.svg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="dealer_item_row_2">
                                                        <div className="dealer_item_row_2_icon">
                                                            <img 
                                                                className="w-100"
                                                                src="/assets/images/address_circle_icon_blue.svg"
                                                            />
                                                        </div>
                                                        <p className="fontSize_12 fontColor_lightGrey dealer_item_row_2_text">
                                                            {value.address}
                                                        </p>
                                                    </div>
                                                </div>
                                            </CustomLink>
                                            <div className="dealerTitle_tagList">
                                                <div className="dealerTitle_tagList_active">
                                                    {!_.isEmpty(value?.brand_name) && <TagButton label={value.brand_name} linkUrl={pageLink.dealer.dealerMidUrl + value.brand_custom_url} extraClass={"tagButton_sm"} />}
                                                    {!_.isEmpty(value?.state) && <TagButton label={value.state} linkUrl={pageLink.dealer.dealerMidUrl + "state-" + value.state.toLowerCase().split(' ').join('-')} extraClass={"tagButton_sm"} />}
                                                    {
                                                        !(JSON.parse(value?.show_room_no)?.length === 0 || (JSON.parse(value?.show_room_no)?.length === 1 && JSON.parse(value?.show_room_no)[0] === '')) &&
                                                        <TagButton label={"Show Room"} disabledLink={true} extraClass={"tagButton_sm"} />
                                                    }
                                                    {
                                                        !(JSON.parse(value?.service_no)?.length === 0 || (JSON.parse(value?.service_no)?.length === 1 && JSON.parse(value?.service_no)[0] === '')) &&
                                                        <TagButton label={"Service"} disabledLink={true} extraClass={"tagButton_sm"} />
                                                    }
                                                    {
                                                        !(JSON.parse(value?.body_paint_no)?.length === 0 || (JSON.parse(value?.body_paint_no)?.length === 1 && JSON.parse(value?.body_paint_no)[0] === '')) &&
                                                        <TagButton label={"Body Paint"} disabledLink={true} extraClass={"tagButton_sm"} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="dealer_item_row_3">
                                                <div onClick={()=>{_handleOnClickDealerCall(value, setPhoneNumberList, setPhoneCallOpen)}} className="secondaryButton cursorPointer">
                                                    <p className="fontSize_14 fontWeight_semiBold fontColor_white">
                                                        Call Now
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return result
                            },[])
                        }
                        <div>
                            {        
                                (isViewMoreButton == true && data?.length >= 6) &&
                                <CustomLink href={pageLink.dealer.dealerMidUrl + brandCustomUrl}>
                                    <div className="viewMore cursorPointer">
                                        <p className="fontSize_14 fontWeight_semiBold fontColor_blue">
                                            {t('view_more')}
                                        </p>
                                        <div className="viewMore_icon">
                                            <ExpendMore color={colorBlueActiveCode} />
                                        </div>
                                    </div>
                                </CustomLink>
                                
                            }
                            {
                                (dealerListTotalRow > data?.length && loading2 == false) &&
                                <div onClick={handleOnClickViewMoreDealer} className="viewMore cursorPointer">
                                    <p className="fontSize_14 fontWeight_semiBold fontColor_blue">
                                        {t('view_more')}
                                    </p>
                                    <div className="viewMore_icon">
                                        <ExpendMore color={colorBlueActiveCode} />
                                    </div>
                                </div>
                            }
                            {
                                loading2 == true &&
                                <LoadMore t={t} />
                            }
                        </div>
                    </div>
                </div>
                :
                <>
                    {
                        isListing == true && <EmptyData t={t} />
                    }
                </>
            }
        </>
        
    )
}